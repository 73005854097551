import Link from 'next/link'
import * as React from 'react'

import styles from './Logo.module.css'
// @ts-ignore
import { home } from '../routes'

interface LogoProps {
  disableLinkToHome?: boolean
}

export function HomeLink (props: LogoProps & { children: React.ReactChild }) {
  if (props.disableLinkToHome) {
    return <div className={styles.root}>{props.children}</div>
  }
  return (
    <Link {...home()}>
      <a className={styles.root}>{props.children}</a>
    </Link>
  )
}

export default function Logo (props: LogoProps) {
  const assetPrefix = process.env.ASSET_PREFIX || ''
  return (
    <HomeLink disableLinkToHome={props.disableLinkToHome}>
      <img
        src={`${assetPrefix}/static/logo.svg`}
        alt='logo'
        data-qa='blacklaneLogo'
      />
    </HomeLink>
  )
}
