import * as React from 'react'

const assetPrefix = process.env.ASSET_PREFIX || ''
const themeColor = '#FF4C00'

export function AppleIcons () {
  return [
    <link
      key='apple-180'
      rel='apple-touch-icon'
      sizes='180x180'
      href={`${assetPrefix}/static/apple-touch-icon.png`}
    />,
    <link
      key='apple-mask'
      rel='mask-icon'
      href={`${assetPrefix}/static/safari-pinned-tab.svg`}
      color={themeColor}
    />
  ]
}

export function MicrosoftIcons () {
  return [
    <meta key='ms-tile' name='msapplication-TileColor' content={themeColor} />,
    <meta
      key='ms-browserconfig'
      name='msapplication-config'
      content={`${assetPrefix}/static/browserconfig.xml`}
    />
  ]
}

export function FavIcons () {
  return [
    <link
      key='favicon-32'
      rel='icon'
      type='image/png'
      sizes='32x32'
      href={`${assetPrefix}/static/favicon-32x32.png`}
    />,
    <link
      key='favicon-16'
      rel='icon'
      type='image/png'
      sizes='16x16'
      href={`${assetPrefix}/static/favicon-16x16.png`}
    />,
    <link
      key='favicon-shortcut'
      rel='shortcut icon'
      href={`${assetPrefix}/static/favicon.ico`}
    />
  ]
}

export default function BrowserIcons () {
  /* The whole thing below has been generated starting from the 96x96@2x.png image, via https://realfavicongenerator.net/ */
  return [
    AppleIcons(),
    MicrosoftIcons(),
    <link
      key='webmanifest'
      rel='manifest'
      href={`${assetPrefix}/static/site.webmanifest`}
    />,
    <meta key='theme' name='theme-color' content={themeColor} />,
    FavIcons()
  ]
}
