import { Dispatch } from 'redux'

// @ts-ignore
import { getJsonApiClient, setEntityModel } from '../../api/apiClient'
// @ts-ignore
import apiRequest from '../../api/request'
import {
  ENTITY as DRIVER_ENTITY,
  INCLUDE_ENTITY_ACCEPTED_BY as INCLUDE_ENTITY_ACCEPTED_BY_DRIVER,
  INCLUDE_ENTITY_ASSIGNED as INCLUDE_ENTITY_ASSIGNED_DRIVER,
  INCLUDE_ENTITY_AVAILABLE as INCLUDE_ENTITY_AVAILABLE_DRIVERS
  // @ts-ignore
} from '../../chauffeurs'
import {
  getSuccessFlashMessages,
  getFailureFlashMessages
} from '../../flashMessage'
import {
  includedEntities as defaultIncludedEntities,
  Model as DefaultModel
  // @ts-ignore
} from '../index'
import {
  actionCreators as listActionCreators,
  getActions as listGetActions,
  initialState as listInitialState,
  getListActionCreators,
  default as listReducer
} from '../../list'
import {
  ENTITY as STATUS_UPDATES_ENTITY,
  INCLUDE_ENTITY_STATUS_UPDATES
  // @ts-ignore
} from '../../statusUpdates'
import {
  ENTITY as VEHICLE_ENTITY,
  INCLUDE_ENTITY_ASSIGNED as INCLUDE_ENTITY_ASSIGNED_VEHICLE,
  INCLUDE_ENTITY_AVAILABLE as INCLUDE_ENTITY_AVAILABLE_VEHICLES
  // @ts-ignore
} from '../../vehicles'

import { Driver as DriverModelType } from 'types/Driver'
import { AllowedIncludeEntity, Entity } from 'types/Entity'
import { ErrorResponse } from 'types/JsonApi'
import {
  Actions,
  AllowedFilters,
  AllowedUIFilters,
  FilterObject,
  State,
  UpdateSearchParamsOptions
} from 'types/List'
import { PlannedRide, Model as BasicRide } from 'types/Rides'
import { Vehicle as VehicleModelType } from 'types/Vehicle'

interface AssignedChauffeur {
  assigned_driver: DriverModelType
  rideId: string
}
interface AssignedVehicle {
  assigned_vehicle: VehicleModelType
  rideId: string
}
type AssignedResource = AssignedChauffeur | AssignedVehicle
type AssignResourceModel = {
  entity: DriverModelType | VehicleModelType
  id: string
  type: AllowedIncludeEntity
}

// Constants
export const ENTITY: Entity = 'ride'
const includedEntities: AllowedIncludeEntity[] = defaultIncludedEntities.concat(
  INCLUDE_ENTITY_ACCEPTED_BY_DRIVER,
  INCLUDE_ENTITY_ASSIGNED_DRIVER,
  INCLUDE_ENTITY_ASSIGNED_VEHICLE,
  INCLUDE_ENTITY_AVAILABLE_DRIVERS,
  INCLUDE_ENTITY_AVAILABLE_VEHICLES,
  INCLUDE_ENTITY_STATUS_UPDATES
)

const allowedFilters: AllowedFilters[] = [
  'booking_number',
  'flight_designator',
  'starts_after',
  'starts_before'
]
const allowedUIFilters: AllowedUIFilters[] = [
  'booking_number',
  'flight_designator',
  'starts_at'
]
const defaultFilter: FilterObject = {
  booking_number: '',
  flight_designator: '',
  group: 'planned',
  starts_after: '',
  starts_before: ''
}

export const flashMessages = {
  success: (_: unknown, value: AssignedResource) => {
    if ((value as AssignedVehicle).assigned_vehicle) {
      return getSuccessFlashMessages(
        'ride.vehicle-assignment.success',
        (value as AssignedVehicle).assigned_vehicle
      )
    }
    return getSuccessFlashMessages(
      'ride.driver-assignment.success',
      (value as AssignedChauffeur).assigned_driver
    )
  },
  failure: (payload: ErrorResponse) =>
    getFailureFlashMessages('ride.resource-assignment.failure', payload)
}

// Actions
export const actions = listGetActions(ENTITY)
export const getActions = () => actions

// Model and initial state
export const Model: PlannedRide = {
  ...(DefaultModel as BasicRide),
  blacklane_phone_number: '',
  passenger_phone_number: '',
  pickup_sign_url: '',
  currency: '',
  flight_info: null,
  status: 'accepted',
  status_updates: {
    jsonApi: 'hasMany',
    type: STATUS_UPDATES_ENTITY
  },
  suitable_service_class: [],
  wait_time_at_pickup_location_allowed: false,
  assigned_driver: {
    jsonApi: 'hasOne',
    type: DRIVER_ENTITY
  },
  available_drivers: {
    jsonApi: 'hasMany',
    type: DRIVER_ENTITY
  },
  available_vehicles: {
    jsonApi: 'hasMany',
    type: VEHICLE_ENTITY
  },
  assigned_vehicle: {
    jsonApi: 'hasOne',
    type: VEHICLE_ENTITY
  },
  accepted_by: {
    jsonApi: 'hasOne',
    type: DRIVER_ENTITY
  },
  accepted_at: ''
}

export const initialState: State<PlannedRide> = {
  ...listInitialState,
  allowedFilters,
  allowedUIFilters,
  defaultFilter,
  filter: {}
}

// Reducer
export default function reducer (
  state: State<PlannedRide> = initialState,
  action: Actions<PlannedRide>
) {
  return listReducer(actions as any, state, action)
}

// Action creators
const listActions = { ...listActionCreators, ...getListActionCreators(ENTITY) }

export const actionCreators = {
  updateSearchParams (options: Partial<UpdateSearchParamsOptions> = {}) {
    return (
      dispatch: Dispatch,
      getState: () => { planned: State<PlannedRide> }
    ) => {
      const state = getState().planned
      dispatch(
        listActions.updateSearchParams({
          allowedFilters: state.allowedFilters,
          defaultFilter: state.defaultFilter,
          reset: options.reset,
          filter: options.filter || {},
          pagination: options.pagination || {}
        })
      )
      return Promise.resolve({})
    }
  },
  loadPlannedRides () {
    return (
      dispatch: Dispatch,
      getState: () => { planned: State<PlannedRide> }
    ) =>
      listActions.loadList({
        dispatch,
        entityName: ENTITY,
        include: includedEntities,
        state: getState().planned
      })
  },
  loadPlannedRide (id: string) {
    return (dispatch: Dispatch) =>
      listActions.loadItem(
        ENTITY,
        {
          id,
          include: includedEntities
        },
        dispatch
      )
  },
  assignResource (props: AssignedResource) {
    const { id, type } = whichEntityToAssign(props)
    return function (dispatch: Dispatch) {
      return apiRequest({
        apiAction: 'patch',
        apiModel: {
          id
        },
        apiUrl: getJsonApiClient()
          .one(ENTITY, props.rideId)
          .relationships()
          .all(type),
        dispatch,
        entityName: ENTITY,
        flashMessages,
        requestPayload: props
      })
    }
  }
}

// Side effects
setEntityModel(ENTITY, Model)

export function whichEntityToAssign (
  resource: AssignedResource
): AssignResourceModel {
  if ((resource as AssignedVehicle).assigned_vehicle) {
    return {
      entity: (resource as AssignedVehicle).assigned_vehicle,
      type: INCLUDE_ENTITY_ASSIGNED_VEHICLE,
      id: (resource as AssignedVehicle).assigned_vehicle.id
    }
  }
  return {
    entity: (resource as AssignedChauffeur).assigned_driver,
    type: INCLUDE_ENTITY_ASSIGNED_DRIVER,
    id: (resource as AssignedChauffeur).assigned_driver.id
  }
}
