import Link from 'next/link'
import React from 'react'

import { HeaderLink, HeaderProps } from './Header'
import styles from './Header.module.css'
import { actionCreators } from '../login'
import Logo from './Logo'
import NavLink from './NavLink'
import RawFormattedMessage from './RawFormattedMessage'
// @ts-ignore
import { help, home, me } from '../routes'
import { Link as LinkType } from '../../types/Link'

interface SecondaryNavigationType {
  firstName: string
  helpLink: LinkType
  homeLink: LinkType
  logout?: typeof actionCreators.logout
  profileLink: LinkType
}

interface PrimaryNavigationType {
  links?: HeaderLink[]
}

interface State {
  isOpen: boolean
}

export function PrimaryNavigation (props: PrimaryNavigationType) {
  return (
    <>
      {props.links &&
        props.links.map(
          ({ link = { canAccess: false }, labelId, isSelectedMatchers }) =>
            link.canAccess !== false && (
              <li className={styles.link} key={labelId}>
                <NavLink
                  activeClassName={styles.isActive}
                  isSelectedMatchers={isSelectedMatchers}
                  labelId={labelId}
                  link={link}
                />
              </li>
            )
        )}
    </>
  )
}

export function SecondaryNavigation (props: SecondaryNavigationType) {
  return (
    <>
      <li className={styles.link}>
        <Link prefetch href={props.profileLink.href} as={props.profileLink.as}>
          <a>{props.firstName}</a>
        </Link>
      </li>
      ,
      <li className={styles.link}>
        <Link href={props.helpLink.href} as={props.helpLink.as}>
          <a target='_blank' data-qa='help'>
            <RawFormattedMessage id='menu.help' />
          </a>
        </Link>
      </li>
      ,
      <li className={styles.link}>
        <Link href={props.homeLink.href} as={props.homeLink.as}>
          <a onClick={props.logout}>
            <RawFormattedMessage id='menu.logout' />
          </a>
        </Link>
      </li>
    </>
  )
}

export class MenuMobile extends React.Component<HeaderProps, State> {
  constructor (props: any) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  toggle (e: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (e.currentTarget.tagName !== 'A') {
      return null
    }
    this.setState({ isOpen: !this.state.isOpen })

    const html = document.querySelector('html')
    const body = html && html.querySelector('body')

    if (html instanceof HTMLElement && body instanceof HTMLElement) {
      html.classList.toggle(`${styles.scrollBlock}`, !this.state.isOpen)
      body.classList.toggle(`${styles.scrollBlock}`, !this.state.isOpen)
    }
  }

  render () {
    const {
      disableLinkToHome,
      firstName,
      isLoggedIn,
      links,
      logout
    } = this.props
    const { isOpen } = this.state

    const burgerMenu = isLoggedIn ? (
      <a
        className={[
          styles.menuMobileIcon,
          isOpen ? styles.menuMobileIconClose : ''
        ].join(' ')}
        onClick={e => this.toggle(e)}
      />
    ) : null

    const nav = (
      <nav key='primary-navigation' onClick={e => this.toggle(e)}>
        <ul className={styles.primaryNavigation}>
          <li className={styles.logoContainer} key='home' data-qa='home'>
            <Logo disableLinkToHome={disableLinkToHome} />
            {burgerMenu}
          </li>
          {isLoggedIn && firstName && isOpen && (
            <>
              <PrimaryNavigation links={links} />
            </>
          )}
        </ul>
      </nav>
    )

    return (
      <>
        {nav}
        {isLoggedIn && firstName && isOpen && (
          <aside
            role='menubar'
            key='secondary-navigation'
            onClick={e => this.toggle(e)}
          >
            <SecondaryNavigation
              firstName={firstName}
              helpLink={help()}
              homeLink={home()}
              logout={logout}
              profileLink={me()}
            />
          </aside>
        )}
      </>
    )
  }
}

export default MenuMobile
