import { ThunkAction } from 'redux-thunk'
import { createStandardAction, ActionType, getType } from 'typesafe-actions'

// @ts-ignore
import apiRequest from '../api/request'
import { AllowedIncludeEntity, Entity } from '../../types/Entity'
import { DriverKpi } from '../../types/DriverKpi'
import { ErrorResponse } from 'types/JsonApi'
// @ts-ignore
import { getJsonApiClient, setEntityModel } from '../api/apiClient'
import { LspKpi } from '../../types/LspKpi'

// Constants
export const DRIVER_KPI_ENTITY: Entity = 'driver_kpi'
export const INCLUDE_ENTITY_DRIVER_KPI: AllowedIncludeEntity = 'driver_kpi'
export const LSP_KPI_ENTITY: Entity = 'lsp_kpi'
export const INCLUDE_ENTITY_LSP_KPI: AllowedIncludeEntity = 'lsp_kpi'

// Model and initial state
export const DriverKpiModel: DriverKpi = {
  average_combined_rating: '',
  average_driver_rating: '',
  average_vehicle_rating: '',
  best_streak: 0,
  current_streak: 0,
  double_five_star_percentage: 0,
  id: ''
}

export const LspKpiModel: LspKpi = {
  average_combined_rating: '',
  average_combined_rating_count: '',
  average_driver_rating: '',
  average_vehicle_rating: '',
  id: ''
}

export const initialState = {
  ...LspKpiModel,
  isFetching: false
}

// Action creators
const actionCreatorsSync = {
  loadLspKpiRequest: createStandardAction('LOAD_LSP_KPI_REQUEST').map(() => ({
    payload: {},
    meta: {},
    error: false
  })),
  loadLspKpiSuccess: createStandardAction('LOAD_LSP_KPI_ITEM_SUCCESS').map(
    ({ result: payload }: { result: LspKpi }) => ({
      payload,
      meta: {},
      error: false
    })
  ),
  loadLspKpiFailure: createStandardAction('LOAD_LSP_KPI_ITEM_FAILURE').map(
    ({ errors: payload }: ErrorResponse) => ({
      payload,
      meta: {},
      error: true
    })
  )
}

export type LspKpiActions = ActionType<typeof actionCreatorsSync>
type ThunkResult<R> = ThunkAction<R, unknown, unknown, LspKpiActions>
export const actionCreatorsAsync = {
  loadLspKpis: (): ThunkResult<void> => dispatch => {
    return apiRequest({
      apiAction: 'get',
      apiUrl: getJsonApiClient().all(LSP_KPI_ENTITY),
      dispatch,
      entityName: LSP_KPI_ENTITY
    })
  }
}
export const actionCreators = { ...actionCreatorsSync, ...actionCreatorsAsync }

// Reducer
export default function reducer (
  state: typeof initialState = initialState,
  action: LspKpiActions
) {
  switch (action.type) {
    case getType(actionCreators.loadLspKpiRequest):
      return {
        ...state,
        isFetching: true
      }
    case getType(actionCreators.loadLspKpiSuccess):
      return {
        ...state,
        isFetching: false,
        ...action.payload
      }
    case getType(actionCreators.loadLspKpiFailure):
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
}

// Side effects
setEntityModel(DRIVER_KPI_ENTITY, DriverKpiModel)
setEntityModel(LSP_KPI_ENTITY, LspKpiModel, { collectionPath: 'lsp_kpi' })
