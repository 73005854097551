import Router from 'next/router'
import { AllowedFilters, ExactListParams } from '../../types/List'

export default function updateUrl (
  { pagination, filter = {} }: ExactListParams,
  allowedFilters: AllowedFilters[]
) {
  const pathname = Router.pathname
  const query: { [propName: string]: any } = {}
  if (pagination && pagination.current > 1) {
    query.page = pagination.current
  }
  allowedFilters.forEach(filterName => {
    const filterValue = filter[filterName]
    if (filterValue) {
      query[filterName] = filterValue
    }
  })
  Router.push(
    {
      pathname,
      query
    },
    {
      pathname: `${process.env.LINK_PREFIX || ''}${pathname}/`,
      query
    }
  )
}
