import { merge } from 'timm'

import { ListResponse } from '../../types/JsonApi'
import { Pagination } from '../../types/Pagination'

export const initialPagination: Pagination = {
  first: 1,
  current: 1,
  next: 1,
  last: 1,
  size: 30,
  block: 'initial'
}

export function computePage (
  request: Pagination,
  responseMeta: ListResponse['meta']
): Pagination {
  const { current } = request
  const { page_count: last = request.last } = responseMeta
  return merge(request, {
    next: Math.min(current + 1, last),
    last
  }) as Pagination
}
