// @flow
import { setEntityModel } from '../api/apiClient'
import type { StatusUpdates } from '../../types/StatusUpdates'
import type { AllowedIncludeEntity, Entity } from '../../types/Entity'

// Constants
export const ENTITY: Entity = 'status_update'
export const INCLUDE_ENTITY_STATUS_UPDATES: AllowedIncludeEntity =
  'status_updates'

// Model and initial state
export const Model: StatusUpdates = {
  message: '',
  timestamp: ''
}

// Side effects
setEntityModel(ENTITY, Model)
