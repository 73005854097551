// @flow
import type { JsonApiActionWithModel } from 'devour-client'
import { mergeIn } from 'timm'
import { unwrapError } from './errors'
import { actionCreators as apiActionCreators } from './index'
import {
  actionCreators as flashMessageActionCreators
  // $FlowIgnore TS import
} from '../flashMessage'
// $FlowIgnore TS import
import type { TypedListAction } from '../../types/Action'
import type { Entity } from '../../types/Entity'
import type {
  ApiFailureResponse,
  ApiRequestOptions,
  ApiSuccessResponse
} from '../../types/JsonApi'

import {
  createSlowConnectionTimeoutHandler,
  removeTimeoutHandler
  // $FlowFixMe TS import
} from './timeout'

function dispatchFailureActions (
  response: ApiFailureResponse
): Promise<ApiFailureResponse> {
  apiActionCreators.loadApiFailure(response).map(response.dispatch)
  response.dispatch(apiActionCreators.loadGenericApiFailure(response))
  flashMessageActionCreators.addFlashMessages(response)

  return Promise.resolve(response)
}

function dispatchSuccessActions (
  response: ApiSuccessResponse
): ApiSuccessResponse {
  let responseToReturn = response
  if (
    response.payload.meta &&
    typeof response.payload.meta.record_count === 'number'
  ) {
    apiActionCreators.loadApiListSuccess(response).map(response.dispatch)
  } else {
    responseToReturn = handlePatchRequest(response)
    apiActionCreators
      .loadApiItemSuccess(responseToReturn)
      .map(response.dispatch)
  }
  flashMessageActionCreators.addFlashMessages(response)
  return responseToReturn
}

function handlePatchRequest (
  apiResponse: ApiSuccessResponse
): ApiSuccessResponse {
  if (apiResponse.payload.data && apiResponse.payload.data.id) {
    return apiResponse
  }
  return mergeIn(apiResponse, ['payload', 'data'], apiResponse.requestPayload)
}

function getUrl (options: ApiRequestOptions): Promise<Object> {
  if (options.apiAction === 'get') {
    return options.apiUrl.get(options.apiOptions)
  } else if (options.apiAction === 'destroy') {
    // $FlowIgnoreMe
    return options.apiUrl.destroy(options.entityName, options.id)
  } else if (options.apiModel) {
    return (options.apiUrl[options.apiAction]: JsonApiActionWithModel)(
      options.apiModel,
      options.apiOptions
    )
  } else {
    throw new Error('Wrong API options')
  }
}

export default function apiRequest (
  options: ApiRequestOptions
): Promise<ApiSuccessResponse | ApiFailureResponse> {
  options.dispatch(apiActionCreators.loadApiRequest(options))
  const timeoutId = createSlowConnectionTimeoutHandler({
    dispatch: options.dispatch,
    flashMessageAction: flashMessageActionCreators.addFlashMessage
  })
  const timeoutHandlerOptions = {
    dispatch: options.dispatch,
    flashMessageAction: flashMessageActionCreators.removeFlashMessage,
    timeoutId
  }
  return getUrl(options)
    .then(payload => {
      removeTimeoutHandler(timeoutHandlerOptions)
      return dispatchSuccessActions({
        ...options,
        isFailure: false,
        payload
      })
    })
    .catch(payload => {
      removeTimeoutHandler(timeoutHandlerOptions)
      return dispatchFailureActions({
        ...options,
        isFailure: true,
        payload: unwrapError(payload)
      })
    })
}
