import { makeUuid } from '../helpers/makeUuid'

interface CreateTimeoutOptions {
  dispatch: any
  flashMessageAction: any
}
interface RemoveTimeoutOptions extends CreateTimeoutOptions {
  timeoutId: string
}

const timeoutMap: { [uuid: string]: number } = {}

export function createSlowConnectionTimeoutHandler ({
  dispatch,
  flashMessageAction
}: CreateTimeoutOptions) {
  const timeoutId = makeUuid()
  const setTimeoutId = window.setTimeout(() => {
    return dispatch(
      flashMessageAction(
        'alert',
        {
          id: 'shared.slow-connection'
        },
        timeoutId
      )
    )
  }, 5000)
  timeoutMap[timeoutId] = setTimeoutId
  return timeoutId
}

export function removeTimeoutHandler ({
  dispatch,
  flashMessageAction,
  timeoutId
}: RemoveTimeoutOptions) {
  dispatch(flashMessageAction(timeoutId))
  window.clearTimeout(timeoutMap[timeoutId])
  delete timeoutMap[timeoutId]
}
