import Cookies from 'js-cookie'
import React, { Component } from 'react'
import { IntlProvider, injectIntl } from 'react-intl'
import * as locales from './availableLocales'

import Loading from '../components/Loading'

export async function loadIntl (locale = locales.defaultLocale) {
  const selectedLocale = Cookies.get('__bl_locale__') || locale
  const messages = await locales.availableMessages[selectedLocale]

  return {
    locale: selectedLocale,
    messages
  }
}

export default function createWithIntl (Page) {
  const IntlPage = injectIntl(Page)
  const childComponentName =
    IntlPage.displayName || IntlPage.name || 'Component'

  return class WithIntl extends Component {
    constructor (props) {
      super(props)
      const { pageProps: { locale = locales.defaultLocale } = {} } = props
      this.state = {
        locale,
        now: Date.now()
      }
      loadIntl(locale).then(intlData => {
        this.setState({
          ...intlData,
          now: Date.now()
        })
      })
    }

    get displayName () {
      return `WithIntl(${childComponentName})`
    }

    render () {
      if (!this.state.messages) {
        return <Loading />
      }
      return (
        <IntlProvider
          locale={this.state.locale}
          messages={this.state.messages}
          initialNow={this.state.now}
        >
          <IntlPage {...this.props} />
        </IntlProvider>
      )
    }
  }
}
