// @flow
import { merge, setIn } from 'timm'

import type {
  Action,
  AsyncAction,
  TypedAction,
  TypedItemAction,
  TypedListAction
  // $FlowIgnore TS import
} from '../../types/Action'
import { getJsonApiClient, setEntityModel } from '../api/apiClient'
import {
  reducer as detailPageReducer,
  getDefaultFlashMessages
  // $FlowFixMe TypeScript import
} from '../detail'
import type {
  Driver as DriverModel,
  UpdateAttribute,
  UpdateAttributeMeta,
  UpdateAttributePayload
} from '../../types/Driver'
import type { AllowedIncludeEntity, Entity } from '../../types/Entity'
import {
  actionCreators as flashMessageActionCreators,
  MessageTypeFailure
  // $FlowIgnore TS import
} from '../flashMessage'
import type { ErrorResponse, OneToOneRelationship } from '../../types/JsonApi'
import {
  actionCreators as listActionCreators,
  getActions as listGetActions,
  initialState as listInitialState,
  getListActionCreators,
  default as listReducer,
  draftId
  // $FlowFixMe TypeScript import
} from '../list'
import type {
  Actions,
  AllowedFilters,
  ExactListParams,
  FilterObject,
  LoadItem,
  LoadList,
  State,
  UpdateSearchParams
} from '../../types/List'
import type { Pagination } from '../../types/Pagination'
import apiRequest from '../api/request'
import type { Role } from '../../types/Role'
import { chauffeurDetail, chauffeursList } from '../routes'
// $FlowIgnore TS import
import { INCLUDE_ENTITY_DRIVER_KPI } from '../kpi'

// Types
export type AllowedStateFilters = 'live' | 'on_hold'

// Constants
export const ENTITY: Entity = 'driver'
export const INCLUDE_ENTITY_ASSIGNED: AllowedIncludeEntity = 'assigned_driver'
export const INCLUDE_ENTITY_AVAILABLE: AllowedIncludeEntity =
  'available_drivers'
export const INCLUDE_ENTITY_ACCEPTED_BY: AllowedIncludeEntity = 'accepted_by'
export const flashMessages = {
  ...getDefaultFlashMessages('chauffeurs'),
  warnAtLeastOneRequired: {
    id: 'chauffeurs.edit.warn.atLeastOneRequired'
  }
}
const allowedFilters: Array<AllowedFilters> = []
const defaultFilter: FilterObject = {
  state: ['live', 'on_hold'].join(',')
}

// Actions
export const actions = {
  ...listGetActions(ENTITY),
  UPDATE_ATTRIBUTE: `${ENTITY}/UPDATE_ATTRIBUTE`
}
export const getActions = () => actions

// Model and initial state
export const Model: DriverModel = {
  driver_kpi: {
    jsonApi: 'hasOne',
    type: INCLUDE_ENTITY_DRIVER_KPI
  },
  email: '',
  first_name: '',
  id: '',
  last_name: '',
  mobile: '',
  roles: [],
  state: 'live',
  title: ''
}

export const modelWithDraftId = merge(Model, {
  id: draftId
})

export const initialState: State<DriverModel> = merge(listInitialState, {
  allowedFilters,
  allowedUIFilters: allowedFilters,
  data: {
    [draftId]: modelWithDraftId
  },
  defaultFilter,
  filter: {}
})

// Reducer
export default function reducer (
  state: State<DriverModel> = initialState,
  action:
    | Actions<DriverModel>
    | TypedAction<UpdateAttributePayload, UpdateAttributeMeta>
) {
  const chauffeurAction = ((action: any):
    | TypedItemAction<DriverModel>
    | TypedListAction<DriverModel>)

  return listReducer(actions, detailPageReducer(state, action), chauffeurAction)
}

// Action creators
const listActions = { ...listActionCreators, ...getListActionCreators(ENTITY) }

export const actionCreators: {
  loadDriver: LoadItem<DriverModel>,
  loadDrivers: LoadList,
  loadDriversWithKpis: LoadList,
  updateSearchParams: UpdateSearchParams,
  warnAtLeastOneRequired: () => TypedAction<*, *>
} = {
  updateSearchParams (options = {}) {
    return (dispatch: *, getState) => {
      const state = getState().drivers
      dispatch(
        listActions.updateSearchParams({
          allowedFilters: state.allowedFilters,
          defaultFilter: state.defaultFilter,
          reset: options.reset,
          filter: options.filter || {},
          pagination: options.pagination || {}
        })
      )
      return Promise.resolve({})
    }
  },
  loadDrivers ({ pagination = {} } = {}) {
    return (dispatch: *, getState) =>
      listActions.loadList({
        dispatch,
        entityName: ENTITY,
        pagination,
        state: getState().drivers
      })
  },
  loadDriversWithKpis () {
    return (dispatch: *, getState) =>
      listActions.loadList({
        dispatch,
        entityName: ENTITY,
        include: ['driver_kpi'],
        state: getState().drivers
      })
  },
  loadDriver (id) {
    return (dispatch: *) =>
      listActions.loadItem(
        ENTITY,
        {
          id
        },
        dispatch
      )
  },
  warnAtLeastOneRequired () {
    return flashMessageActionCreators.addFlashMessage(
      MessageTypeFailure,
      flashMessages.warnAtLeastOneRequired
    )
  }
}

// Side effects
export const serializers = {
  chauffeur (chauffeur: DriverModel) {
    let params: {
      attributes: $Shape<DriverModel>,
      type: 'drivers',
      id?: string
    } = {
      attributes: {
        email: chauffeur.email,
        first_name: chauffeur.first_name,
        last_name: chauffeur.last_name,
        mobile: chauffeur.mobile,
        roles: chauffeur.roles,
        title: chauffeur.title
      },
      type: 'drivers'
    }
    if (chauffeur.id !== draftId) {
      params.id = chauffeur.id
    }
    return params
  },
  assignedChauffeur (assignedChauffeur: DriverModel) {
    return {
      id: assignedChauffeur.id,
      type: 'drivers'
    }
  }
}
setEntityModel(ENTITY, Model, {
  serializer: serializers.chauffeur
})
setEntityModel(INCLUDE_ENTITY_ASSIGNED, Model, {
  collectionPath: INCLUDE_ENTITY_ASSIGNED,
  type: ENTITY,
  serializer: serializers.assignedChauffeur
})
