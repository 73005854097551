// @flow
import { merge, omit } from 'timm'

import { actions as apiActions } from '../../api'
import apiRequest from '../../api/request'
import { getJsonApiClient, setEntityModel } from '../../api/apiClient'
import type { PendingOffer as PendingOffersType } from '../../../types/PendingOffers'
// $FlowIgnore TS import
import type { GetState } from '../../../types/ReduxType'
// $FlowIgnore TS import
import type { Action, ActionsObject } from '../../../types/Action'
import type { Entity } from '../../../types/Entity'

// Constants
export const ENTITY: Entity = 'pending_offers'

// Actions
export const actions = {
  ITEM_SUCCESS: apiActions.getItemSuccessActionName(ENTITY),
  REMOVE: apiActions.getFailureActionName(ENTITY, 'record_not_found'),
  FAILURE: apiActions.getFailureActionName(ENTITY),
  REQUEST: apiActions.getRequestActionName(ENTITY)
}

type State = {
  error: boolean,
  isFetching: boolean,
  state: ?$PropertyType<PendingOffersType, 'state'>,
  token: $PropertyType<PendingOffersType, 'token'>
}
// InitialState
export const initialState: State = {
  isFetching: false,
  error: false,
  token: '',
  state: 'reject'
}

// Reducer
export default function reducer (
  state: typeof initialState = initialState,
  { payload, type, meta }: Action
) {
  switch (type) {
    case actions.REQUEST:
      return merge(state, {
        isFetching: true,
        state: meta.state
      })
    case actions.REMOVE:
    case actions.FAILURE:
      return merge(state, payload, {
        error: true,
        isFetching: false
      })
    case actions.ITEM_SUCCESS:
      return merge(state, payload, {
        error: false,
        isFetching: false
      })
    default:
      return state
  }
}

// Action creators
export const actionCreators = {
  setPendingOffer (
    token: $PropertyType<PendingOffersType, 'token'>,
    state: $PropertyType<PendingOffersType, 'state'>
  ) {
    return function (dispatch: Dispatch) {
      return apiRequest({
        apiAction: 'patch',
        apiModel: {
          state,
          id: token
        },
        apiUrl: getJsonApiClient().one(ENTITY, token),
        dispatch,
        entityName: ENTITY,
        id: token,
        requestPayload: {
          state
        }
      })
    }
  }
}

// Side effects
setEntityModel(ENTITY, initialState, {
  serializer: resourceAttributes => {
    return {
      attributes: omit(resourceAttributes, 'id'),
      id: resourceAttributes.id,
      type: ENTITY
    }
  }
})
