// @flow
import { merge, mergeDeep } from 'timm'

import type {
  TypedAction,
  TypedItemAction,
  TypedListAction
  // $FlowIgnore TS import
} from '../../../types/Action'
import {
  actions as apiActions,
  actionCreators as apiActionCreators
} from '../../api'
import { getJsonApiClient, setEntityModel } from '../../api/apiClient'
import {
  ENTITY as DRIVER_ENTITY,
  INCLUDE_ENTITY_ASSIGNED as INCLUDE_ENTITY_ASSIGNED_DRIVER,
  INCLUDE_ENTITY_ACCEPTED_BY as INCLUDE_ENTITY_ACCEPTED_BY_DRIVER
} from '../../chauffeurs'
import type { AllowedIncludeEntity, Entity } from '../../../types/Entity'
import {
  includedEntities as defaultIncludedEntities,
  Model as DefaultModel
} from '../index'
import {
  actionCreators as listActionCreators,
  getActions as getListActions,
  initialState as listInitialState,
  getListActionCreators,
  default as listReducer
  // $FlowFixMe TypeScript imports
} from '../../list'
import type {
  Actions,
  AllowedFilters,
  FilterObject,
  LoadItem,
  LoadList,
  State,
  UpdateSearchParams
} from '../../../types/List'
import type { Pagination } from '../../../types/Pagination'
import {
  ENTITY as REVIEW_ENTITY,
  getActions as getReviewActions,
  INCLUDE_ENTITY as INCLUDE_ENTITY_REVIEW
} from '../../reviews'
import type { ReviewItemAction } from '../../../types/Review'
// $FlowIgnore typescript import
import type { AfterAcceptedRideModel, Status } from '../../../types/Rides'
import {
  ENTITY as STATUS_UPDATES_ENTITY,
  INCLUDE_ENTITY_STATUS_UPDATES
} from '../../statusUpdates'
import {
  ENTITY as VEHICLE_ENTITY,
  INCLUDE_ENTITY_ASSIGNED as INCLUDE_ENTITY_ASSIGNED_VEHICLE
} from '../../vehicles'
// $FlowIgnore TS import
import { initialPagination } from '../../withPagination'

// Constants
export const ENTITY: Entity = 'finished_ride'
export const INCLUDE_ENTITY: AllowedIncludeEntity = 'finished_rides'

function getIncludedEntities (): Array<AllowedIncludeEntity> {
  return defaultIncludedEntities.concat(
    INCLUDE_ENTITY_ASSIGNED_DRIVER,
    INCLUDE_ENTITY_ASSIGNED_VEHICLE,
    INCLUDE_ENTITY_REVIEW,
    INCLUDE_ENTITY_ACCEPTED_BY_DRIVER,
    INCLUDE_ENTITY_STATUS_UPDATES
  )
}
const allowedFilters: Array<AllowedFilters> = [
  'booking_number',
  'flight_designator'
]
const allowedUIFilters: Array<AllowedFilters> = [
  'booking_number',
  'flight_designator'
]
const defaultFilter: FilterObject = {
  booking_number: '',
  flight_designator: '',
  status: 'finished,no_show'
}

// Actions
export const actions = getListActions(ENTITY)
export const getActions = () => actions

// Model and initial state
export const Model: AfterAcceptedRideModel = merge(DefaultModel, {
  accepted_at: '',
  accepted_by: {
    jsonApi: 'hasOne',
    type: DRIVER_ENTITY
  },
  assigned_driver: {
    jsonApi: 'hasOne',
    type: DRIVER_ENTITY
  },
  assigned_vehicle: {
    jsonApi: 'hasOne',
    type: VEHICLE_ENTITY
  },
  blacklane_phone_number: '',
  is_reviewable: false,
  passenger_phone_number: '',
  pickup_sign_url: '',
  currency: '',
  review: {
    jsonApi: 'hasOne',
    type: REVIEW_ENTITY
  },
  status: 'finished',
  status_updates: {
    jsonApi: 'hasMany',
    type: STATUS_UPDATES_ENTITY
  },
  suitable_service_class: [],
  wait_time_at_pickup_location_allowed: false,
  total_wait_time: 0,
  review_deadline: '',
  missed_review_deadline: false
})

export const initialState: State<AfterAcceptedRideModel> = Object.assign(
  {},
  listInitialState,
  {
    allowedFilters,
    allowedUIFilters,
    defaultFilter,
    filter: {}
  }
)

// Reducer
export default function reducer (
  state: State<AfterAcceptedRideModel> = initialState,
  action: Actions<AfterAcceptedRideModel> | ReviewItemAction
) {
  const reviewActions = getReviewActions()
  switch (action.type) {
    case reviewActions.ITEM_SUCCESS: {
      const reviewAction = ((action: any): ReviewItemAction)
      return mergeDeep(state, {
        data: {
          [reviewAction.meta.request.ride.id]: {
            is_reviewable: false,
            review: reviewAction.payload
          }
        }
      })
    }
    default: {
      const rideAction = ((action: any):
        | TypedItemAction<AfterAcceptedRideModel>
        | TypedListAction<AfterAcceptedRideModel>)
      return listReducer(actions, state, rideAction)
    }
  }
}

// Action creators
const listActions = { ...listActionCreators, ...getListActionCreators(ENTITY) }

export const actionCreators: {
  loadFinishedRides: LoadList,
  loadFinishedRide: LoadItem<AfterAcceptedRideModel>,
  updateSearchParams: UpdateSearchParams
} = {
  updateSearchParams (options = {}) {
    return (dispatch: *, getState) => {
      const state = getState().finished
      dispatch(
        listActions.updateSearchParams({
          allowedFilters: state.allowedFilters,
          defaultFilter: state.defaultFilter,
          reset: options.reset,
          filter: options.filter || {},
          pagination: options.pagination || {}
        })
      )
      return Promise.resolve({})
    }
  },
  loadFinishedRides () {
    return (dispatch: *, getState) =>
      listActions.loadList({
        dispatch,
        entityName: ENTITY,
        include: getIncludedEntities(),
        state: getState().finished
      })
  },
  loadFinishedRide (id) {
    return (dispatch: *) =>
      listActions.loadItem(
        ENTITY,
        {
          id,
          include: getIncludedEntities()
        },
        dispatch
      )
  }
}

// Side effects
setEntityModel(ENTITY, Model)
