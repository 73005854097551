// @flow
import type { AllowedIncludeEntity } from '../../types/Entity'
import {
  INCLUDE_ENTITY as LOCATION_ENTITY,
  INCLUDE_ENTITY_PICKUP,
  INCLUDE_ENTITY_DROPOFF
  // $FlowIgnore TS import
} from '../locations'
// $FlowIgnore TS import
import type { Model as BaseModel } from '../../types/Rides'

// Types

// Constants
export const includedEntities: Array<AllowedIncludeEntity> = [
  INCLUDE_ENTITY_PICKUP,
  INCLUDE_ENTITY_DROPOFF
]

// Actions

// Model and initial state
export const Model: BaseModel = {
  id: '',
  starts_at: '',
  duration: 0,
  estimated_duration: 0,
  price_in_cents: 0,
  booking_type: 'transfer',
  distance: 0,
  service_class: '',
  flight_designator: '',
  special_requests: '',
  passenger_first_name: '',
  passenger_last_name: '',
  passenger_title: '',
  booking_number: '',
  pickup_sign: '',
  pickup_location: {
    jsonApi: 'hasOne',
    type: LOCATION_ENTITY
  },
  dropoff_location: {
    jsonApi: 'hasOne',
    type: LOCATION_ENTITY
  },
  updatedAt: 0
}

// Reducer

// Action creators

// Side effects
