import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators, compose, Dispatch } from 'redux'

import {
  actionCreators,
  Model,
  MessageTypeFailure,
  MessageTypeAlert,
  MessageTypeSuccess
} from './index'
// @ts-ignore
import { State } from '../store/reducer'
// @ts-ignore
import { Action } from '../../types/Action'
import styles from './FlashMessage.module.css'

interface MapStateToProps {
  flashMessage: typeof Model | null
}
interface MapDispatchToProps {
  removeFlashMessage: (uuid: string) => Action
}

type FlashMessageProps = MapStateToProps &
  MapDispatchToProps &
  WrappedComponentProps

const showMessageForSeconds = {
  [MessageTypeFailure]: 5,
  [MessageTypeAlert]: 30,
  [MessageTypeSuccess]: 5
}

export class FlashMessage extends React.Component<FlashMessageProps> {
  componentDidUpdate () {
    const { removeFlashMessage, flashMessage } = this.props
    if (flashMessage && flashMessage.uuid && flashMessage.messageType) {
      setTimeout(() => {
        if (flashMessage.uuid) {
          removeFlashMessage(flashMessage.uuid)
        }
      }, showMessageForSeconds[flashMessage.messageType] * 1000)
    }
  }

  render () {
    const { flashMessage } = this.props
    if (!flashMessage) {
      return null
    }

    // flashMessage.message can also be a <FormattedMessage /> component
    if (React.isValidElement(flashMessage.message)) {
      return (
        <FlashMessageContainer flashMessage={flashMessage}>
          <p className={styles.link}>{flashMessage.message}</p>
        </FlashMessageContainer>
      )
    }

    if (!flashMessage.message.id) {
      return null
    }

    const {
      message: { values = {}, ...messageDescriptor }
    } = flashMessage
    const formattedMessage = this.props.intl.formatMessage(
      messageDescriptor,
      values
    )
    if (
      formattedMessage === messageDescriptor.id &&
      process.env.NODE_ENV !== 'test'
    ) {
      // message.id is the default text used when a translation isn't available
      return null
    }

    return (
      <FlashMessageContainer flashMessage={flashMessage}>
        <p
          className={styles.link}
          dangerouslySetInnerHTML={{
            __html: formattedMessage
          }}
        />
      </FlashMessageContainer>
    )
  }
}

type FlashMessageContainerProps = {
  flashMessage: typeof Model
  children: React.ReactNode
}
function FlashMessageContainer ({
  flashMessage,
  children
}: FlashMessageContainerProps) {
  const className =
    flashMessage.messageType !== '' ? styles[flashMessage.messageType] : ''

  return (
    <section className={className} data-qa='flash-message'>
      {children}
    </section>
  )
}

function mapStateToProps ({
  flashMessage: flashMessages = []
}: State): MapStateToProps {
  return {
    flashMessage:
      flashMessages.length > 0 ? flashMessages[flashMessages.length - 1] : null
  }
}

function mapDispatchToProps (dispatch: Dispatch): MapDispatchToProps {
  return bindActionCreators(
    {
      removeFlashMessage: actionCreators.removeFlashMessage
    },
    dispatch
  )
}

export default compose<React.ComponentType<{}>>(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(FlashMessage)
