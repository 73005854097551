export const availableMessages = {
  de: import('../../translations/de.json'),
  en: import('../../translations/en.json'),
  'en-GB': import('../../translations/en.json'),
  'en-US': import('../../translations/en.json'),
  fr: import('../../translations/en.json')
}

export const defaultLocale = 'en-GB'
export const availableLanguages = ['en', 'de', 'fr']
