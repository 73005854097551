import { useMe } from '../me'
import { useEffect } from 'react'

interface IntercomProps {
  appId: string
}

export function Intercom ({ appId }: IntercomProps) {
  const me = useMe()

  useEffect(() => {
    // @ts-ignore
    if (!window.Intercom) {
      return
    }

    if (me.id && me.intercomUserHash) {
      // @ts-ignore
      window.Intercom('update', {
        app_id: appId,
        user_id: me.id,
        user_hash: me.intercomUserHash,
        'User Type': 'chauffeur'
      })
    } else {
      // @ts-ignore
      window.Intercom('shutdown')

      // @ts-ignore
      window.Intercom('boot', {
        app_id: appId
      })
    }
  }, [me.id])

  return null
}
