import {
  AnyRide,
  Offer,
  FinishedRide,
  AfterAcceptedRideModel
} from '../../types/Rides'

export function isOffer (rideModel: AnyRide | null): rideModel is Offer {
  const ride = rideModel as Offer
  return (
    ride &&
    ride.currency !== undefined &&
    ride.ends_at !== undefined &&
    ride.is_final_price !== undefined &&
    ride.next_price_change_in !== undefined &&
    ride.route !== undefined
  )
}

export function isRide (
  rideModel: AnyRide | null
): rideModel is AfterAcceptedRideModel {
  const ride = rideModel as AfterAcceptedRideModel
  return ride && ride.accepted_at !== undefined && ride.status !== undefined
}

export function isFinished (
  rideModel: AnyRide | null
): rideModel is FinishedRide {
  const ride = rideModel as FinishedRide
  return (
    ride &&
    typeof ride.is_reviewable === 'boolean' &&
    [
      'finished',
      'driver_emergency_cancel',
      'no_show',
      'canceled_by_admin',
      'canceled_by_passenger'
    ].includes(ride.status)
  )
}
