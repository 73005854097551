import isEqual from 'lodash.isequal'

import getParamFromURL from '../getParamFromUrl'
// @ts-ignore
import { initialPagination } from '../withPagination'
import {
  AllowedFilters,
  ExactListParams,
  FilterObject,
  UpdateSearchParamsOptions
} from '../../types/List'

type MergeListParams = {
  allowedFilters: AllowedFilters[]
  filter: FilterObject
}

function mergeListParams ({
  allowedFilters,
  filter = {}
}: MergeListParams): ExactListParams {
  return {
    pagination: {
      ...initialPagination,
      current: Number(getParamFromURL('page')) || 1
    },
    filter: {
      ...filter,
      ...allowedFilters.reduce(
        (filters: { [index in AllowedFilters]?: string }, currentFilter) => {
          const filterValueFromUrl = getParamFromURL(currentFilter)
          if (filterValueFromUrl) {
            filters[currentFilter] = filterValueFromUrl
          }
          return filters
        },
        {}
      )
    }
  }
}

interface ComputeListParams extends UpdateSearchParamsOptions {
  allowedFilters: AllowedFilters[]
  defaultFilter: FilterObject
}

export default function computeListParams ({
  allowedFilters,
  defaultFilter,
  filter,
  pagination
}: ComputeListParams) {
  const currentListParamsFromURL = mergeListParams({
    allowedFilters,
    filter: defaultFilter
  })
  const requestedListParams: ExactListParams = {
    ...currentListParamsFromURL,
    filter: {
      ...currentListParamsFromURL.filter,
      ...filter
    },
    pagination: {
      ...currentListParamsFromURL.pagination,
      ...pagination
    }
  }
  return {
    current: currentListParamsFromURL,
    didChange: Boolean(!isEqual(currentListParamsFromURL, requestedListParams)),
    requested: requestedListParams
  }
}
