import { FormattedMessage } from 'react-intl'
import Cookies from 'js-cookie'
import React, { createRef, RefObject } from 'react'

import IconClose from '../Icon/IconClose'
import styles from './Cookie.module.css'

interface CookieProps {
  locale?: string
  notice?: string
}

export class Cookie extends React.Component<
  CookieProps,
  { isCookiePolicyAccepted: boolean }
> {
  ref: RefObject<HTMLAnchorElement>
  cookieName = '__bl_icpa'
  constructor (args: any) {
    super(args)

    this.ref = createRef<HTMLAnchorElement>()

    this.state = {
      isCookiePolicyAccepted: Boolean(Cookies.get(this.cookieName))
    }
  }

  componentDidMount () {
    if (this.ref.current) {
      this.ref.current.focus()
    }
  }

  acceptCookie () {
    Cookies.set(this.cookieName, '1')
    this.setState({
      isCookiePolicyAccepted: true
    })
  }

  render () {
    if (this.state.isCookiePolicyAccepted) {
      return null
    }

    const contentElementID = 'cookie-policy-content'

    return (
      <div className={styles.container} id='cookie-policy-main'>
        <div
          aria-labelledby={contentElementID}
          className={styles.content}
          id={contentElementID}
          role='alertdialog'
        >
          <FormattedMessage id='cookie'>
            {txt => (
              <div
                className={styles.policyText}
                id='cookie-policy-text'
                dangerouslySetInnerHTML={{ __html: txt as any }}
              />
            )}
          </FormattedMessage>
          <button
            aria-label='Dismiss notice'
            className={styles.dismissNotice}
            onClick={() => this.acceptCookie()}
          >
            <IconClose className={styles.dismissNoticeIcon} />
          </button>
        </div>
      </div>
    )
  }
}

export default Cookie
