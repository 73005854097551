// @flow
import { merge, set } from 'timm'
import type {
  Action,
  TypedErrorAction,
  TypedRequestAction,
  ItemAction,
  ListAction,
  TypedAction,
  TypedItemAction,
  TypedListAction
  // $FlowIgnore TS import
} from '../../types/Action'
import { createJsonApiClient, destroyJsonApiClient } from './apiClient'
import { actions as cookieActions } from './cookies'
import type { Entity } from '../../types/Entity'
// $FlowIgnore typescript
import getEnvVariable from '../helpers/env'
import { errorCodes } from './errors'
import type {
  ApiFailureResponse,
  ApiRequestOptions,
  ApiSuccessResponse,
  ErrorCode,
  ErrorResponse,
  Item,
  ListResponse,
  ItemResponse
} from '../../types/JsonApi'
import type { FilterObject } from '../../types/List'
import {
  actions as loginActions,
  actionCreators as loginActionCreators
  // $FlowFixMe TypeScript import
} from '../login'
import type { Pagination } from '../../types/Pagination'
// $FlowIgnore TS import
import { computePage } from '../withPagination'

// Constants
const GENERIC_API_ENTITY: Entity = '_api_'
const EFFECT_REQUEST: 'REQUEST' = 'REQUEST'
const EFFECT_LIST_SUCCESS: 'LIST_SUCCESS' = 'LIST_SUCCESS'
const EFFECT_ITEM_SUCCESS: 'ITEM_SUCCESS' = 'ITEM_SUCCESS'
const EFFECT_FAILURE: 'FAILURE' = 'FAILURE'
const EFFECT_REMOVE: 'REMOVE' = 'REMOVE'
type APIActionEffect =
  | typeof EFFECT_REQUEST
  | typeof EFFECT_LIST_SUCCESS
  | typeof EFFECT_ITEM_SUCCESS
  | typeof EFFECT_FAILURE
  | typeof EFFECT_REMOVE
type ApiFailureInputParams = {
  entityName: Entity,
  id?: string,
  payload: ErrorResponse,
  requestPayload?: Object
}

// Actions
function getAPIRequestActionName (
  effect: APIActionEffect,
  entityName: Entity
): string {
  return `load_${entityName}_${effect}`.toUpperCase()
}
export const actions = {
  getListSuccessActionName (entityName: Entity) {
    return getAPIRequestActionName(EFFECT_LIST_SUCCESS, entityName)
  },
  getItemSuccessActionName (entityName: Entity) {
    return getAPIRequestActionName(EFFECT_ITEM_SUCCESS, entityName)
  },
  getApiFailureActionName () {
    return getAPIRequestActionName(EFFECT_FAILURE, GENERIC_API_ENTITY)
  },
  getFailureActionName (entityName: Entity, code?: ErrorCode) {
    switch (code) {
      case errorCodes.NOT_FOUND:
        return getAPIRequestActionName(EFFECT_REMOVE, entityName)
      default:
        return getAPIRequestActionName(EFFECT_FAILURE, entityName)
    }
  },
  getRequestActionName (entityName: Entity) {
    return getAPIRequestActionName(EFFECT_REQUEST, entityName)
  }
}

// Model and initial state
export const initialState: {
  accessToken: string,
  apiUrl: string,
  expiresIn: number,
  didCheckForCookie: boolean,
  userUuid: ?string
} = {
  accessToken: '',
  apiUrl: getEnvVariable('HADES_ENDPOINT'),
  expiresIn: 0,
  didCheckForCookie: false,
  userUuid: null
}

// Reducer
export default function reducer (
  state: typeof initialState = initialState,
  { payload, type }: Action
) {
  switch (type) {
    case loginActions.LOAD_AUTH_TOKEN_SUCCESS:
    case cookieActions.READ_AUTH_TOKEN_COOKIE_SUCCESS: {
      if (!payload || payload instanceof Error) {
        return state
      }
      const newState: typeof initialState = merge(state, {
        accessToken: payload.access_token,
        apiUrl: getEnvVariable('HADES_ENDPOINT'),
        didCheckForCookie: true
      })
      createJsonApiClient(newState)
      return newState
    }
    case loginActions.LOGOUT:
    case cookieActions.READ_AUTH_TOKEN_COOKIE_FAILURE: {
      destroyJsonApiClient()
      return merge(state, {
        accessToken: '',
        didCheckForCookie: true
      })
    }
    default:
      return state
  }
}

// Action creators
export const actionCreators = {
  loadApiRequest ({
    entityName,
    requestPayload = {}
  }: {
    entityName: $PropertyType<ApiRequestOptions, 'entityName'>,
    requestPayload: $PropertyType<ApiRequestOptions, 'requestPayload'>
  }): TypedRequestAction {
    return {
      type: actions.getRequestActionName(entityName),
      error: false,
      payload: {},
      meta: {
        isFetching: true,
        request: requestPayload
      }
    }
  },
  loadApiListSuccess ({
    entityName,
    filter,
    payload,
    requestPayload = {},
    pagination
  }: ApiSuccessResponse): Array<TypedListAction<*>> {
    if (
      !payload.meta ||
      typeof payload.meta.record_count !== 'number' ||
      !pagination
    ) {
      throw new Error('Invalid list payload')
    }
    const { data, links, meta } = payload
    return [
      {
        type: actions.getListSuccessActionName(entityName),
        error: false,
        meta: Object.assign({}, meta, {
          filter,
          isFetching: false,
          links,
          pagination: computePage(pagination, meta),
          request: requestPayload
        }),
        payload: useIdAsObjectKey(data)
      }
    ]
  },
  loadApiItemSuccess ({
    entityName,
    payload,
    requestPayload = {}
  }: ApiSuccessResponse): Array<TypedItemAction<*>> {
    const { data } = payload
    if ((payload.meta && payload.meta.record_count) || Array.isArray(data)) {
      throw new Error('Invalid item payload')
    }
    if (!data) {
      return []
    }
    return [
      {
        type: actions.getItemSuccessActionName(entityName),
        error: false,
        meta: {
          id: data.id,
          isFetching: false,
          request: requestPayload
        },
        payload: data
      }
    ]
  },
  loadGenericApiFailure ({
    entityName,
    id,
    payload,
    requestPayload = {}
  }: ApiFailureInputParams) {
    return {
      type: actions.getApiFailureActionName(),
      error: true,
      meta: {
        entityName,
        id,
        isFetching: false,
        request: requestPayload
      },
      payload: payload.errors
    }
  },
  loadApiFailure ({
    entityName,
    id,
    payload,
    requestPayload = {}
  }: ApiFailureInputParams): Array<TypedErrorAction | TypedAction<*, *>> {
    return payload.errors.reduce((errorActions, currentError) => {
      if (currentError.status === 401) {
        return errorActions.concat(
          loginActionCreators.sessionExpired(currentError)
        )
      }
      return errorActions.concat(
        ({
          type: actions.getFailureActionName(
            entityName,
            currentError.code || currentError.title
          ),
          error: true,
          meta: {
            id,
            isFetching: false,
            request: requestPayload
          },
          payload: currentError
        }: TypedErrorAction)
      )
    }, [])
  }
}

// Side effects
createJsonApiClient(initialState)

function useIdAsObjectKey (data: ?Array<Item>): Object {
  if (!data) {
    return {
      data: {}
    }
  }
  return data.reduce(
    (returningElement, currentElement) =>
      set(returningElement, currentElement.id, currentElement),
    {}
  )
}
