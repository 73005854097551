// @ts-ignore TS import
import { setEntityModel } from '../api/apiClient'
import { AllowedIncludeEntity, Entity } from '../../types/Entity'
import { Location } from '../../types/Location'

export const ENTITY: Entity = 'location'
export const INCLUDE_ENTITY: AllowedIncludeEntity = 'location'
export const INCLUDE_ENTITY_PICKUP: AllowedIncludeEntity = 'pickup_location'
export const INCLUDE_ENTITY_DROPOFF: AllowedIncludeEntity = 'dropoff_location'

export const Model: Location = {
  formatted_address_de: '',
  formatted_address_en: '',
  tags: [],
  id: '',
  latitude: '',
  longitude: ''
}

setEntityModel(ENTITY, Model)
