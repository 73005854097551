import Head from 'next/head'
import * as React from 'react'
import { WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators, compose, Dispatch } from 'redux'
import Cookie from '../components/Cookie/Cookie'
import '../theme/norbert/index.css'
import '../theme/norbert/phoneInput.css'

// @ts-ignore
import { actionCreators } from '../api/cookies'
import BrowserIcons from '../components/BrowserIcons'
import FlashMessage from '../flashMessage/FlashMessage'
import { Footer } from '../components/Footer'
import Header from '../components/Header'
// @ts-ignore
import withIntl from '../withIntl'

import styles from './Layout.module.css'

interface DispatchToProps {
  readAuthTokenCookie: typeof actionCreators.readAuthTokenCookie
}

interface LayoutProps extends DispatchToProps, WrappedComponentProps {
  children: React.ReactElement<unknown>
  pageProps: {
    disableLinkToHome?: boolean
  }
}

export class Layout extends React.Component<LayoutProps> {
  componentDidMount () {
    this.props.readAuthTokenCookie()
  }

  render () {
    const { children, intl, pageProps = {} } = this.props
    const locale = intl.locale.split('-')[0]

    return (
      <div className={styles.root}>
        <Head>
          <title>
            {intl.formatMessage({
              id: 'title'
            })}
          </title>
          {BrowserIcons()}
        </Head>
        <Header
          key='app-header'
          disableLinkToHome={pageProps.disableLinkToHome}
        />
        <FlashMessage />
        <main className={styles.main} key='app-main'>
          {children}
        </main>
        <Footer key='app-footer' locale={locale} />
        <Cookie />
      </div>
    )
  }
}

function mapDispatchToProps (dispatch: Dispatch): DispatchToProps {
  return bindActionCreators(
    {
      readAuthTokenCookie: actionCreators.readAuthTokenCookie
    },
    dispatch
  )
}

export default compose<React.ComponentType<{}>>(
  connect(undefined, mapDispatchToProps),
  withIntl
  // @ts-ignore
)(Layout)
