import App, { Container } from 'next/app'
import withRedux from 'next-redux-wrapper'
import React from 'react'
import { Provider } from 'react-redux'

import Layout from '../src/layout/Layout'
import { initStore } from '../src/store/configureStore'
import { Intercom } from '../src/intercom'
import { Toaster } from 'react-hot-toast'

export class Kairos extends App {
  static async getInitialProps ({ Component, ctx }) {
    return {
      pageProps: Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {}
    }
  }

  render () {
    const { Component, pageProps, store } = this.props

    return (
      <Container>
        <Provider store={store}>
          <Layout pageProps={pageProps}>
            <Component {...pageProps} />
            <Intercom appId={process.env.INTERCOM_APP_ID} />
            <Toaster />
          </Layout>
        </Provider>
      </Container>
    )
  }
}

export default withRedux(initStore)(Kairos)
