// @flow
import Router from 'next/router'
import { merge, mergeDeep } from 'timm'

import { actions as apiActions } from '../api'
import { getJsonApiClient, setEntityModel } from '../api/apiClient'
import apiRequest from '../api/request'
import {
  getActions as getRideActions,
  INCLUDE_ENTITY as INCLUDE_RIDE_ENTITY
} from '../rides/finished'
import {
  getSuccessFlashMessages,
  getFailureFlashMessages
  // $FlowIgnore TS import
} from '../flashMessage'
// $FlowIgnore TS import
import type { AsyncAction, TypedAction } from '../../types/Action'
import type { AllowedIncludeEntity, Entity } from '../../types/Entity'
import type { ErrorResponse } from '../../types/JsonApi'
// $FlowIgnore TS import
import type { GetState } from '../../types/ReduxType'
import type { Review, ReviewWithIncludes, State } from '../../types/Review'
// $FlowIgnore typescript import
import type { AfterAcceptedRideModel, RideItemAction } from '../../types/Rides'

// Types
type UpdateAttributeMeta = {|
  id: $PropertyType<AfterAcceptedRideModel, 'id'>
|}
type UpdateAttribute = {|
  attribute: $Keys<Review>,
  value: $Values<Review>
|}

// Constants
export const ENTITY: Entity = 'review'
export const INCLUDE_ENTITY: AllowedIncludeEntity = 'review'
export const flashMessages = {
  success: () => getSuccessFlashMessages('review.posted'),
  failure: (payload: ErrorResponse) =>
    getFailureFlashMessages('review.failure', payload)
}

// Actions
const actions = {
  UPDATE_REVIEW_ATTRIBUTE: `${ENTITY}/UPDATE_REVIEW_ATTRIBUTE`,
  ITEM_SUCCESS: apiActions.getItemSuccessActionName(ENTITY),
  FAILURE: apiActions.getFailureActionName(ENTITY),
  REQUEST: apiActions.getRequestActionName(ENTITY)
}
export const getActions = () => actions

// Model and initial state
export const Model: ReviewWithIncludes = {
  id: '',
  distance: null,
  driver_comment: '',
  duration: null,
  ride: {
    jsonApi: 'hasOne',
    type: INCLUDE_RIDE_ENTITY
  }
}

export const initialState: State = {
  data: {},
  error: false,
  isFetching: false
}

// Reducer
export default function reducer (
  state: State = initialState,
  action: RideItemAction<AfterAcceptedRideModel>
) {
  const finishedRideActions = getRideActions()
  switch (action.type) {
    case finishedRideActions.ITEM_SUCCESS: {
      const { payload } = action
      if (!payload.is_reviewable || !payload.review) {
        return state
      }
      return {
        ...state,
        data: {
          ...state.data,
          [payload.review.id]: {
            ...Model,
            ...payload.review,
            ride: {
              id: action.meta.id,
              type: INCLUDE_RIDE_ENTITY,
              booking_type: action.payload.booking_type,
              distance: action.payload.distance,
              duration: action.payload.duration
            }
          }
        }
      }
    }
    default:
      return state
  }
}

// Action creators
export const actionCreators = {
  postReview (review: {
    ...ReviewWithIncludes,
    id: string | null
  }): AsyncAction {
    return function (dispatch: *, getState: GetState) {
      const isEditingReview = Boolean(review.id)
      return apiRequest({
        apiAction: isEditingReview ? 'patch' : 'post',
        apiModel: review,
        apiUrl: isEditingReview
          ? // $FlowIgnoreMe
            getJsonApiClient().one(ENTITY, review.id)
          : getJsonApiClient().all(ENTITY),
        dispatch,
        entityName: ENTITY,
        flashMessages,
        requestPayload: review
      })
    }
  }
}

// Side effects
setEntityModel(ENTITY, Model, {
  serializer: resourceAttributes => {
    const attributes = {}
    const validAttributes = ['distance', 'duration', 'driver_comment']
    Object.entries(resourceAttributes).forEach(([key, value]) => {
      if (typeof value !== 'undefined' && validAttributes.includes(key)) {
        attributes[key] = resourceAttributes[key]
      }
    })
    // $FlowIgnore – no clue why this is failing. But well, we're phasing out flow soon anyway
    return Object.assign(
      {
        attributes: attributes,
        type: 'reviews',
        relationships: {
          ride: {
            data: {
              type: 'finished_rides',
              id: resourceAttributes.ride.id
            }
          }
        }
      },
      resourceAttributes.id ? { id: resourceAttributes.id } : {}
    )
  }
})
