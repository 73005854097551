import Link from 'next/link'
// @ts-ignore
import Router, { withRouter } from 'next/router'
import * as React from 'react'
// @ts-ignore
import { doesRouteMatchWith } from '../routes'
import { useT } from '../helpers/useT'

export type NavLinkProps = {
  isSelectedMatchers?: Link[]
  labelId: string
  link: Link
  lspId?: string
  activeClassName: string
  className: string
  router: Router
}

export function NavLink ({
  activeClassName,
  className,
  labelId,
  link,
  isSelectedMatchers = [],
  router
}: NavLinkProps) {
  const t = useT()
  if (!router || !link) {
    return null
  }
  const isSelected = doesRouteMatchWith(router, isSelectedMatchers)

  return (
    <Link prefetch href={link.href} as={link.as}>
      <a
        data-qa={labelId}
        data-qa-selected={isSelected}
        className={[className, isSelected ? activeClassName : ''].join(' ')}
      >
        {t(labelId)}
      </a>
    </Link>
  )
}

export default withRouter(NavLink)
