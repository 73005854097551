// @flow
import { merge, mergeDeep, omit, setIn } from 'timm'

// $FlowIgnore TS import
import type { TypedItemAction, TypedListAction } from '../../../types/Action'
import {
  actions as apiActions,
  actionCreators as apiActionCreators
} from '../../api'
import { getJsonApiClient, setEntityModel } from '../../api/apiClient'
import type { BookingType } from '../../../types/BookingType'
import type { AllowedIncludeEntity, Entity } from '../../../types/Entity'
import * as errorCodes from '../../api/errors'
import {
  includedEntities as defaultIncludedEntities,
  Model as DefaultModel
} from '../index'
import {
  actionCreators as listActionCreators,
  getActions as listGetActions,
  getListActionCreators,
  initialState as listInitialState,
  default as listReducer
  // $FlowFixMe TypeScript import
} from '../../list'
import type {
  Actions,
  AllowedFilters,
  FilterObject,
  LoadItem,
  LoadList,
  State,
  UpdateSearchParams
} from '../../../types/List'
import {
  ENTITY as LOCATION_ENTITY,
  INCLUDE_ENTITY_PICKUP,
  INCLUDE_ENTITY_DROPOFF,
  Model as LocationModel
  // $FlowIgnore TS import
} from '../../locations'
import type { Pagination } from '../../../types/Pagination'
// $FlowIgnore TS import
import type { GetState } from '../../../types/ReduxType'
// $FlowIgnore TS import
import type { OfferModel } from '../../../types/Rides'
// $FlowIgnore TS import
import { initialPagination } from '../../withPagination'

// Constants
export const ENTITY: Entity = 'offer'
export const INCLUDE_ENTITY: AllowedIncludeEntity = 'offers'
const includedEntities: Array<AllowedIncludeEntity> = defaultIncludedEntities
const allowedFilters: Array<AllowedFilters> = []
const allowedUIFilters: Array<AllowedFilters> = []
const defaultFilter: FilterObject = {}

// Actions
export const actions = listGetActions(ENTITY)
export const getActions = () => actions

// Model and initial state
export const Model: OfferModel = merge(DefaultModel, {
  currency: '',
  ends_at: '',
  next_price_change_in: 0,
  is_final_price: false,
  route: ''
})

export const initialState: State<OfferModel> = Object.assign(
  {},
  listInitialState,
  {
    allowedFilters,
    allowedUIFilters,
    defaultFilter,
    filter: {}
  }
)

// Reducer
export default function reducer (
  state: State<OfferModel> = initialState,
  action: Actions<OfferModel>
) {
  return listReducer(actions, state, action)
}

// Action creators
const listActions = { ...listActionCreators, ...getListActionCreators(ENTITY) }

export const actionCreators: {
  loadOffer: LoadItem<OfferModel>
} = {
  loadOffer (id) {
    return (dispatch: *) =>
      listActions.loadItem(
        ENTITY,
        {
          id,
          include: includedEntities
        },
        dispatch
      )
  }
}

// Side effects
setEntityModel(ENTITY, Model)
