// @flow
import { merge } from 'timm'

import { setEntityModel } from '../api/apiClient'
// $FlowIgnore TS import
import { DRAFT_ID, getDefaultFlashMessages } from '../detail'
import type { Vehicle as VehicleModel } from '../../types/Vehicle'
import type { AllowedIncludeEntity, Entity } from '../../types/Entity'
import type { Actions, LoadItem, LoadList, State } from '../../types/List'
// $FlowIgnore TS import
import type { TypedItemAction, TypedListAction } from '../../types/Action'

import {
  actionCreators as listActionCreators,
  getActions as listGetActions,
  initialState as listInitialState,
  getListActionCreators,
  default as listReducer,
  draftId
  // $FlowFixMe TypeScript import
} from '../list'

import type { UpdateSearchParams } from '../../types/List'

// Types

// Constants
export const ENTITY: Entity = 'vehicle'
export const INCLUDE_ENTITY_ASSIGNED: AllowedIncludeEntity = 'assigned_vehicle'
export const INCLUDE_ENTITY_AVAILABLE: AllowedIncludeEntity =
  'available_vehicles'
export const flashMessages = {
  ...getDefaultFlashMessages('vehicles')
}

// Actions
export const actions = listGetActions(ENTITY)

// Model and initial state
export const Model: VehicleModel = {
  business_districts: [],
  color: '',
  id: '',
  model: '',
  number: '',
  plate: '',
  state: 'live',
  vehicle_class: '',
  year: ''
}

export const initialState: State<VehicleModel> = merge(listInitialState, {
  data: {
    [DRAFT_ID]: {
      ...Model,
      id: DRAFT_ID
    }
  },
  isFetching: false
})

// Reducer
export default function reducer (
  state: State<VehicleModel> = initialState,
  action: Actions<VehicleModel>
) {
  switch (action.type) {
    default:
      const vehiclesAction = ((action: any):
        | TypedItemAction<VehicleModel>
        | TypedListAction<VehicleModel>)
      return listReducer(actions, state, vehiclesAction)
  }
}

// Action creators
const listActions = { ...listActionCreators, ...getListActionCreators(ENTITY) }

export const actionCreators: {
  loadVehicle: LoadItem<VehicleModel>,
  loadVehicles: LoadList,
  updateSearchParams: UpdateSearchParams
} = {
  loadVehicle (id) {
    return (dispatch: *) =>
      listActions.loadItem(
        ENTITY,
        {
          id
        },
        dispatch
      )
  },
  loadVehicles () {
    return (dispatch: *, getState) =>
      listActions.loadList({
        dispatch,
        entityName: ENTITY,
        state: getState().vehicles
      })
  },
  updateSearchParams (options = {}) {
    return (dispatch: *, getState) => {
      const state = getState().vehicles
      dispatch(
        listActions.updateSearchParams({
          allowedFilters: state.allowedFilters,
          defaultFilter: state.defaultFilter,
          reset: options.reset,
          filter: options.filter || {},
          pagination: options.pagination || {}
        })
      )
      return Promise.resolve({})
    }
  }
}

// Side effects
export const serializers = {
  vehicle (vehicle: VehicleModel) {
    let params: {
      attributes: $Shape<VehicleModel>,
      type: 'vehicles',
      id?: string
    } = {
      attributes: {
        color: vehicle.color,
        model: vehicle.model,
        plate: vehicle.plate,
        vehicle_class: vehicle.vehicle_class,
        year: vehicle.year
      },
      type: 'vehicles'
    }
    if (vehicle.id !== draftId) {
      params.id = vehicle.id
    }
    return params
  },
  assignedVehicle (assignedVehicle: VehicleModel) {
    return {
      id: assignedVehicle.id,
      type: 'vehicles'
    }
  }
}

setEntityModel(ENTITY, Model, {
  serializer: serializers.vehicle
})
setEntityModel(INCLUDE_ENTITY_ASSIGNED, Model, {
  collectionPath: 'assigned_vehicle',
  type: ENTITY,
  serializer: serializers.assignedVehicle
})
