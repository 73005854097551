import Link from 'next/link'
import * as React from 'react'

import { HeaderProps } from './Header'
import styles from './Header.module.css'
import Logo from './Logo'
import NavLink from './NavLink'
import RawFormattedMessage from './RawFormattedMessage'
// @ts-ignore
import { help, me } from '../routes'

export class MenuDesktop extends React.Component<HeaderProps> {
  render () {
    const {
      disableLinkToHome,
      firstName,
      isLoggedIn,
      links,
      logout
    } = this.props
    const profileLink = me()
    const helpLink = help()
    return [
      <aside role='menubar' key='secondary-navigation'>
        <ul className={styles.secondaryNavigation}>
          <li>
            {isLoggedIn && (
              <Link prefetch href={profileLink.href} as={profileLink.as}>
                <a data-qa={firstName}>{firstName}</a>
              </Link>
            )}
          </li>
          <li>
            {isLoggedIn && (
              <Link href={helpLink.href} as={helpLink.as}>
                <a target='_blank' data-qa='help'>
                  <RawFormattedMessage id='menu.help' />
                </a>
              </Link>
            )}
          </li>
          {isLoggedIn && (
            <li>
              <a onClick={logout} data-qa='logout'>
                <RawFormattedMessage id='menu.logout' />
              </a>
            </li>
          )}
        </ul>
      </aside>,
      <nav key='primary-navigation'>
        <ul className={styles.primaryNavigation}>
          <li className={styles.logoContainer} key='home' data-qa='home'>
            <Logo disableLinkToHome={disableLinkToHome} />
          </li>
          {isLoggedIn &&
            links &&
            links.map(
              ({ link = { canAccess: false }, labelId, isSelectedMatchers }) =>
                link.canAccess !== false && (
                  <li key={labelId} data-qa={labelId}>
                    <NavLink
                      activeClassName={styles.isActive}
                      isSelectedMatchers={isSelectedMatchers}
                      labelId={labelId}
                      link={link}
                    />
                  </li>
                )
            )}
        </ul>
      </nav>
    ]
  }
}

export default MenuDesktop
