import * as React from 'react'
import { bindActionCreators, compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
// @ts-ignore
import { withRouter } from 'next/router'

// @ts-ignore
import isMobile from '../helpers/isMobile'
// $FlowFixMe TypeScript import
import { actionCreators } from '../login'
// @ts-ignore
import MenuMobile from './MenuMobile'
// @ts-ignore
import MenuDesktop from './MenuDesktop'
import {
  chauffeurAdd,
  chauffeurDetail,
  chauffeurDocs,
  chauffeurEdit,
  chauffeursList,
  finished,
  finishedDetail,
  lspDocs,
  offerDetail,
  offers,
  onboardingDashboard,
  pendingOffers,
  planned,
  plannedDetail,
  ratingsList,
  vehicleDetail,
  vehicleDocs,
  vehiclesList
  // @ts-ignore
} from '../routes'
// @ts-ignore
import { State } from '../store/reducer'
import { Role } from '../../types/Role'
import { Link } from '../../types/Link'
import { useMe } from '../me'
import { OnboardingStatus } from '../onboarding/overview/onboardingData'

export type HeaderLink = {
  labelId: string
  link: Link
  isSelectedMatchers: Link[]
}

function getRidesLink (roles: Role[]) {
  const offersLink = offers({ userRoles: roles })
  const plannedLink = planned({ userRoles: roles })
  const finishedLink = finished({ userRoles: roles })
  return [offersLink, plannedLink, finishedLink].find(link => link.canAccess)
}

interface StateToProps {
  isLoggedIn: boolean
  firstName: string | null
  links: HeaderLink[]
}

interface DispatchToProps {
  logout: typeof actionCreators.logout
}

export interface HeaderProps
  extends Partial<StateToProps>,
    Partial<DispatchToProps> {
  disableLinkToHome?: boolean
}

export const getMenuLinks = (
  me: ReturnType<typeof useMe>,
  params: { lspId: string }
): HeaderLink[] => {
  const links = []
  const roles = me.roles
  const ridesListLink = getRidesLink(roles)
  const chauffeursListLink = chauffeursList({ userRoles: roles })
  const lspLink = lspDocs({
    userRoles: roles,
    qs: { id: params ? params.lspId : '' }
  })
  const vehiclesListLink = vehiclesList({ userRoles: roles })
  const ratingsListLink = ratingsList({ userRoles: roles })
  const onboardingDashboardLink = onboardingDashboard({ userRoles: roles })

  if (
    onboardingDashboardLink.canAccess &&
    me.lsp.onboardingStatus !== OnboardingStatus.Completed
  ) {
    links.push({
      labelId: 'onboarding.title',
      link: onboardingDashboardLink,
      isSelectedMatchers: [onboardingDashboard()]
    })
  } else {
    if (ridesListLink) {
      links.push({
        labelId: 'rides.title',
        link: ridesListLink,
        isSelectedMatchers: [
          finished(),
          finishedDetail({}),
          offerDetail({}),
          offers(),
          pendingOffers({}),
          planned(),
          plannedDetail({})
        ]
      })
    }
    if (chauffeursListLink.canAccess) {
      links.push({
        labelId: 'shared.chauffeurs',
        link: chauffeursListLink,
        isSelectedMatchers: [
          chauffeurAdd(),
          chauffeurDetail({}),
          chauffeurEdit({}),
          chauffeurDocs({}),
          chauffeursList()
        ]
      })
    }
    if (vehiclesListLink.canAccess) {
      links.push({
        labelId: 'shared.vehicles',
        link: vehiclesListLink,
        isSelectedMatchers: [vehiclesList(), vehicleDetail({}), vehicleDocs({})]
      })
    }
    if (lspLink.canAccess) {
      links.push({
        labelId: 'company.title',
        link: lspLink,
        isSelectedMatchers: [lspDocs()]
      })
    }
    if (ratingsListLink.canAccess) {
      links.push({
        labelId: 'ratings.title',
        link: ratingsListLink,
        isSelectedMatchers: [ratingsList()]
      })
    }
  }
  return links
}

export class Header extends React.Component<HeaderProps> {
  render () {
    return (
      <header>
        {isMobile() ? (
          <MenuMobile {...this.props} />
        ) : (
          <MenuDesktop {...this.props} />
        )}
      </header>
    )
  }
}

function mapStateToProps ({ login: { isLoggedIn }, me }: State): StateToProps {
  return {
    isLoggedIn,
    firstName: me.firstName,
    links: getMenuLinks(me, { lspId: me.lsp.id })
  }
}

function mapDispatchToProps (dispatch: Dispatch): DispatchToProps {
  return bindActionCreators(
    {
      logout: actionCreators.logout
    },
    dispatch
  )
}

export default compose<typeof Header>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header)
