// @ts-ignore
import { actions as apiActions } from '../api'
import { actions as loginActions, LoginActions } from '../login'
// @ts-ignore
import { actions as cookieActions } from '../api/cookies'
import { MeActions } from '../me'
// @ts-ignore
import { getActions as getOfferActions } from '../rides/offers'
// @ts-ignore
import { getActions as getPlannedActions } from '../rides/planned'
// @ts-ignore
import { getActions as getFinishedActions } from '../rides/finished'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export const API_FAILURE = apiActions.getApiFailureActionName()
export const { SESSION_EXPIRED } = loginActions
export const { READ_AUTH_TOKEN_COOKIE_FAILURE } = cookieActions
export const {
  LIST_SUCCESS: OFFERS_LIST_SUCCESS,
  ITEM_SUCCESS: OFFERS_ITEM_SUCCESS
} = getOfferActions()
export const {
  LIST_SUCCESS: PLANNED_LIST_SUCCESS,
  ITEM_SUCCESS: PLANNED_ITEM_SUCCESS
} = getPlannedActions()
export const {
  LIST_SUCCESS: FINISHED_LIST_SUCCESS,
  ITEM_SUCCESS: FINISHED_ITEM_SUCCESS
} = getFinishedActions()

export const listActions = [
  OFFERS_LIST_SUCCESS,
  PLANNED_LIST_SUCCESS,
  FINISHED_LIST_SUCCESS
]
export const itemActions = [
  OFFERS_ITEM_SUCCESS,
  PLANNED_ITEM_SUCCESS,
  FINISHED_ITEM_SUCCESS
]

export type TrackingActions =
  | LoginActions
  | MeActions
  | typeof OFFERS_LIST_SUCCESS
  | typeof OFFERS_ITEM_SUCCESS
  | typeof PLANNED_LIST_SUCCESS
  | typeof PLANNED_ITEM_SUCCESS
  | typeof FINISHED_LIST_SUCCESS
  | typeof FINISHED_ITEM_SUCCESS

export function getEventPrefix (actionType: string) {
  switch (actionType) {
    case OFFERS_LIST_SUCCESS:
    case OFFERS_ITEM_SUCCESS:
      return 'offers'
    case PLANNED_LIST_SUCCESS:
    case PLANNED_ITEM_SUCCESS:
      return 'planned'
    case FINISHED_LIST_SUCCESS:
    case FINISHED_ITEM_SUCCESS:
      return 'finished'
  }
}
