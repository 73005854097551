import { getType } from 'typesafe-actions'
// @ts-ignore
import { getJsonApiClient, setEntityModel } from '../api/apiClient'
import * as dataLayer from './dataLayer'
import { actionCreators as loginActionCreators } from '../login'
import { actionCreators as meActionCreators } from '../me'
import * as trackingHelpers from './trackingHelpers'
import { datadogRum } from './datadog'

// Types

// Constants

// Actions

// Model and initial state
export const initialState = {}

// Reducer
export function reducer (
  state: typeof initialState = initialState,
  action: any
) {
  switch (action.type) {
    case trackingHelpers.API_FAILURE: {
      dataLayer.onApiFailure(action)
      return state
    }
    case getType(meActionCreators.loadMeSuccess): {
      dataLayer.onLogin(action)

      datadogRum()?.setUser({
        id: action.payload.id
      })

      return state
    }
    case trackingHelpers.READ_AUTH_TOKEN_COOKIE_FAILURE:
    case getType(loginActionCreators.logoutAction):
    case getType(loginActionCreators.sessionExpiredAction): {
      datadogRum()?.clearUser()
      return state
    }
    case trackingHelpers.OFFERS_LIST_SUCCESS:
    case trackingHelpers.PLANNED_LIST_SUCCESS:
    case trackingHelpers.FINISHED_LIST_SUCCESS: {
      dataLayer.onListSuccess(action)
      return state
    }
    case trackingHelpers.OFFERS_ITEM_SUCCESS:
    case trackingHelpers.PLANNED_ITEM_SUCCESS:
    case trackingHelpers.FINISHED_ITEM_SUCCESS: {
      dataLayer.onItemSuccess(action)
      return state
    }
    default:
      return state
  }
}

// Action creators

// Side effects
