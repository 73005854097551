import { Dispatch } from 'redux'
// @ts-ignore
import { JsonApiInstance } from 'devour-client'
import { createStandardAction, getType, ActionType } from 'typesafe-actions'
// import { ThunkAction } from 'redux-thunk'

// @ts-ignore
import { actions as apiActions } from '../api'
// @ts-ignore
import { getJsonApiClient, setEntityModel } from '../api/apiClient'
// @ts-ignore
import { AllowedIncludeEntity, Entity } from '../../types/Entity'
// @ts-ignore
import * as errorCodes from '../api/errors'
import {
  AllowedFilters,
  FilterObject,
  UpdateSearchParamsOptions,
  UpdateSearchParamsState,
  AllowedUIFilters
} from '../../types/List'
import computeListParams from './listParams'
// @ts-ignore
import { Pagination } from '../../types/Pagination'
// @ts-ignore
import apiRequest from '../api/request'
import updateListUrl from './updateUrl'
// @ts-ignore
import { initialPagination } from '../withPagination'
import { ThunkAction } from 'redux-thunk'

// Constants
export const draftId = 'draft'

// Actions
const updateSearchParamsMapper = ({
  allowedFilters = [],
  defaultFilter = {},
  filter = {},
  pagination,
  reset = false
}: UpdateSearchParamsOptions & UpdateSearchParamsState) => {
  const { didChange, requested } = computeListParams({
    allowedFilters,
    defaultFilter,
    filter,
    pagination
  })
  if (didChange || reset) {
    updateListUrl(requested, allowedFilters)
  }
  return {
    error: false,
    meta: {
      shouldUpdateTimestamp: Boolean(didChange || reset)
    },
    payload: requested
  }
}

// @ts-todo Create a more consistent naming convention for actions so we don't mix cases
export function getListActionCreators<
  LU extends string,
  R extends string,
  LS extends string,
  IS extends string,
  INF extends string,
  F extends string
> (e: Entity) {
  const entity = e.toUpperCase()
  return {
    updateSearchParams: createStandardAction(
      `${entity}_LIST_PARAMS_UPDATE` as LU
    ).map(updateSearchParamsMapper),
    REQUEST: createStandardAction(`LOAD_${entity}_REQUEST` as R).map(
      updateSearchParamsMapper
    ),
    LIST_SUCCESS: createStandardAction(`LOAD_${entity}_LIST_SUCCESS` as LS).map(
      updateSearchParamsMapper
    ),
    ITEM_SUCCESS: createStandardAction(`LOAD_${entity}_ITEM_SUCCESS` as IS).map(
      updateSearchParamsMapper
    ),
    ITEM_NOT_FOUND: createStandardAction(`LOAD_${entity}_REMOVE` as INF).map(
      updateSearchParamsMapper
    ),
    FAILURE: createStandardAction(`LOAD_${entity}_FAILURE` as F).map(
      updateSearchParamsMapper
    )
  }
}

export function getActions (entity: Entity) {
  const actions = getListActionCreators(entity)
  return {
    updateSearchParams: getType(actions.updateSearchParams),
    REQUEST: getType(actions.REQUEST),
    LIST_SUCCESS: getType(actions.LIST_SUCCESS),
    ITEM_SUCCESS: getType(actions.ITEM_SUCCESS),
    ITEM_NOT_FOUND: getType(actions.ITEM_NOT_FOUND),
    FAILURE: getType(actions.FAILURE)
  }
}

// Model and initial state
// @ts-todo Put this in types/List?
export interface ListState<T> {
  readonly allowedFilters: AllowedFilters[]
  readonly allowedUIFilters: AllowedUIFilters[]
  readonly initDraft?: T
  readonly data: {
    [id: string]: T
  }
  readonly defaultFilter: FilterObject
  readonly error: boolean
  readonly filter: FilterObject
  readonly isFetchingItem: boolean
  readonly isFetchingList: boolean
  readonly lastUpdate: number | null
  readonly pagination: Pagination
  readonly recordCount: number
  readonly resetWhenNotFound?: boolean
}

export const initialState = {
  allowedFilters: [],
  allowedUIFilters: [],
  data: {},
  defaultFilter: {},
  error: false,
  filter: {},
  isFetchingItem: false,
  isFetchingList: false,
  lastUpdate: null,
  pagination: initialPagination,
  recordCount: 0
}

interface LoadList {
  apiUrl?: JsonApiInstance
  dispatch: Dispatch
  entityName: Entity
  include?: AllowedIncludeEntity[]
  pagination?: Partial<Pagination>
  state: any // @ts-todo generic list state
}

// Regular actions
const actionCreatorsSync = {}
type ListActions = ActionType<typeof actionCreatorsSync>

// Async actions
type ThunkResult<R> = ThunkAction<R, void, void, ListActions>

const actionCreatorsAsync = {
  loadList: ({
    apiUrl,
    dispatch,
    entityName,
    include = [],
    pagination = {},
    state
  }: LoadList): ThunkResult<void> => {
    const result = apiRequest({
      apiAction: 'get',
      apiOptions: {
        ...pageObject({ ...state.pagination, ...pagination }),
        ...includeObject(include),
        ...filterObject(state.filter)
      },
      apiUrl: apiUrl || getJsonApiClient().all(entityName),
      dispatch,
      entityName,
      filter: state.filter,
      pagination: state.pagination
    })
    return result
  },

  loadItem: (
    entityName: Entity,
    {
      id,
      include = []
    }: {
      id: string
      include?: AllowedIncludeEntity[]
    },
    dispatch: Dispatch
  ): ThunkResult<void> =>
    apiRequest({
      apiAction: 'get',
      apiOptions: includeObject(include),
      apiUrl: getJsonApiClient().one(entityName, id),
      dispatch,
      entityName,
      id,
      requestPayload: {
        id
      }
    })
}

export const actionCreators = { ...actionCreatorsSync, ...actionCreatorsAsync }

// @ts-todo add another generic type variable to the generic reducer se we can take advantage of
// ActionType and create a union type out of the combined sync and async action creators

// Reducer
export default function reducer<T> (
  actions: any,
  state: ListState<T>,
  action: any
): ListState<T> {
  const defaultState = {
    error: false,
    isFetchingItem: false,
    isFetchingList: false
  }
  switch (action.type) {
    case actions.updateSearchParams: {
      const filter = action.payload.filter
      const lastUpdate = action.meta.shouldUpdateTimestamp
        ? new Date().getTime()
        : state.lastUpdate
      return {
        ...state,
        filter,
        lastUpdate,
        pagination: {
          ...state.pagination,
          current: action.payload.pagination.current
        }
      }
    }

    case actions.REQUEST: {
      const isFetchingItem = Boolean(action.meta.request.id)
      return {
        ...state,
        error: false,
        isFetchingItem,
        isFetchingList: !isFetchingItem
      }
    }

    case actions.LIST_SUCCESS: {
      const data = { ...action.payload }
      const filter = action.meta.filter || {}

      if (draftId in state.data) {
        data[draftId] = state.data[draftId]
      }
      return {
        ...state,
        filter: { ...state.filter, ...filter },
        data,
        error: false,
        isFetchingList: false,
        pagination: action.meta.pagination,
        recordCount: action.meta.record_count
      }
    }

    case actions.ITEM_SUCCESS: {
      let rideId = action.meta.id
      if (action.meta.request && action.meta.request.rideId) {
        rideId = action.meta.request.rideId
      }
      return {
        ...state,
        error: false,
        isFetchingItem: false,
        data: {
          ...state.data,
          [rideId]: action.payload
        }
      }
    }

    case actions.ITEM_NOT_FOUND: {
      let updatedState: Partial<ListState<T>> = {
        data: {
          ...state.data
        }
      }
      if (action.meta.id) {
        const { [action.meta.id]: _remove, ...data } = state.data
        updatedState = {
          data
        }
      }
      if (state.resetWhenNotFound) {
        updatedState = {
          data: {},
          recordCount: 0,
          pagination: {
            ...initialPagination
          }
        }
      }
      return {
        ...state,
        ...updatedState,
        error: true,
        isFetchingList: false,
        isFetchingItem: false
      }
    }

    case actions.FAILURE:
      return { ...state, ...defaultState, error: true }

    default:
      return state
  }
}

// Side effects
function pageObject (pagination: Pagination) {
  return {
    page: {
      number: pagination.current,
      size: pagination.size
    }
  }
}

function includeObject (include: AllowedIncludeEntity[]): { include?: string } {
  return include && include.length > 0
    ? {
        include: include.join(',')
      }
    : {}
}

type Filter = {
  filter?: { [key in AllowedFilters]?: string }
}

function filterObject (filter: FilterObject) {
  return Object.entries(filter).reduce((filterObj: Filter, [key, value]) => {
    if (!filterObj.filter) {
      filterObj.filter = {}
    }
    if (value !== '') {
      filterObj.filter[key as AllowedFilters] = value
    }
    return filterObj
  }, {})
}
