import { ActionType } from 'typesafe-actions'

import { actionCreators as meActionCreators } from '../me'
import { isOffer } from '../rides/typeGuards'
import { TypedItemAction, TypedListAction } from '../../types/Action'
import { AnyRide } from '../../types/Rides'
import { getEventPrefix } from './trackingHelpers'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export function onLogin (
  action: ActionType<typeof meActionCreators.loadMeSuccess>
) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    user_uuid: action.payload.id,
    lsp_uuid: (action.payload.lsp && action.payload.lsp.id) || '',
    version: 2
  })
}

export function onListSuccess (action: TypedListAction<AnyRide>) {
  window.dataLayer = window.dataLayer || []
  const prefix = getEventPrefix(action.type)
  const ridesList: AnyRide[] = Object.values(action.payload)
  const rides = ridesList.map(ride => {
    const rideData = {
      [`${prefix}_item_ride_uuid`]: ride.id
    }
    if (isOffer(ride)) {
      return {
        [`${prefix}_item_ends_at`]: ride.ends_at,
        [`${prefix}_item_price`]: ride.price_in_cents,
        ...rideData
      }
    }
    return rideData
  })

  window.dataLayer.push({
    event: `${prefix}_list_viewed`,
    [`${prefix}_filter`]: action.meta.filter,
    [`${prefix}_list_rides`]: rides,
    [`${prefix}_page_count`]: action.meta.page_count,
    [`${prefix}_page_current`]: action.meta.pagination.current,
    [`${prefix}_page_size`]: action.meta.pagination.size
  })
}

export function onItemSuccess (action: TypedItemAction<AnyRide>) {
  window.dataLayer = window.dataLayer || []
  const prefix = getEventPrefix(action.type)
  window.dataLayer.push({
    event: `${prefix}_item_viewed`,
    [`${prefix}_item_booking_number`]: action.payload.booking_number,
    [`${prefix}_item_price`]: action.payload.price_in_cents,
    [`${prefix}_item_ride_uuid`]: action.payload.id
  })
}

export function onApiFailure (action: TypedItemAction<any>) {
  const baseEvent = {
    event: 'api_error',
    api_route: action.meta.entityName,
    api_uuid: action.meta.id
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(
    ...action.payload.map((error: any = {}) => {
      return {
        ...baseEvent,
        api_translation_key: error.title,
        api_status: error.status,
        api_detail: error.detail
      }
    })
  )
}
