import Router from 'next/router'
import { Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { ActionType } from 'typesafe-actions'

// @ts-ignore
import { getJsonApiClient, setEntityModel } from '../api/apiClient'
// @ts-ignore
import apiRequest from '../api/request'
import {
  actionCreators as flashMessageActionCreators,
  getSuccessFlashMessages,
  getFailureFlashMessages,
  MessageType
} from '../flashMessage'
import { LoadItem, List } from 'types/List'
import { Entity, Section } from 'types/Entity'
import { ErrorResponse } from 'types/JsonApi'
import { WithUuid, DraftId } from 'types/Item'
import { LinkCreator, Link } from 'types/Link'
import { Tab } from '../components/Tabs'

// Types

export type ValidateItem<T> = (
  props: T,
  options: {
    section: Section
  }
) =>
  | true
  | {
      type: MessageType
      error: {
        id: string
      }
    }
export interface StateProps<T> {
  addPage?: LinkCreator
  detailPage: LinkCreator
  docsPage?: LinkCreator
  editPage?: LinkCreator
  entityName: Entity
  flashMessages: {
    success: any
    failure: any
  }
  listPage: LinkCreator
  listPageCurrent: number
  tabs: Tab[]
  isFetchingItem: boolean
  section: Section
  validateItem: ValidateItem<T>
  value: T
}
export interface DispatchProps<T> {
  addFlashMessage: typeof flashMessageActionCreators.addFlashMessage
  loadItem: LoadItem<T>
}
interface StoreItemOptions {
  detailPage: LinkCreator
  docsPage?: LinkCreator
  flashMessages: {
    success: (_: any) => Array<{ id: string; values?: any }>
    failure: (_: any) => Array<{ id: string; values?: any }>
  }
  listPage: LinkCreator
  entityName: Entity
}
interface DeleteItemOptions {
  entityName: Entity
  flashMessages: {
    success: (_: any) => Array<{ id: string; values?: any }>
    failure: (_: any) => Array<{ id: string; values?: any }>
  }
  listPage: LinkCreator
}

// Constants
export const DRAFT_ID: DraftId = 'draft'
export const actions = {
  UPDATE_ATTRIBUTE: 'detail/UPDATE_ATTRIBUTE',
  RESET_DRAFT: 'detail/RESET_DRAFT'
}

// Sync actions
export const actionCreatorsSync = {}
type DetailActions = ActionType<typeof actionCreatorsSync>

// Async actions
type ThunkResult = ThunkAction<Promise<any>, any, void, DetailActions>
const actionCreatorsAsync = {
  storeItem: <I extends WithUuid>(
    item: I,
    options: StoreItemOptions
  ): ThunkResult => {
    const isAdd = item.id === DRAFT_ID
    const apiClient = getJsonApiClient()
    let apiAction: string
    let apiUrl: string
    if (isAdd) {
      apiAction = 'post'
      apiUrl = apiClient.all(options.entityName)
    } else {
      apiAction = 'patch'
      apiUrl = apiClient.one(options.entityName, item.id)
    }
    return (dispatch: Dispatch) =>
      apiRequest({
        apiAction,
        apiModel: item,
        apiUrl,
        dispatch,
        entityName: options.entityName,
        flashMessages: options.flashMessages,
        id: item.id,
        requestPayload: item
      }).then((data: { payload: any }) => {
        if (data.payload.errors) {
          return Promise.reject(data)
        }
        let redirectLink: Link
        if (isAdd && options.docsPage) {
          redirectLink = options.docsPage({ qs: { id: data.payload.data.id } })
        } else if (isAdd) {
          redirectLink = options.listPage()
        } else {
          redirectLink = options.detailPage({ qs: { id: item.id } })
        }
        Router.push(redirectLink.href, redirectLink.as)

        return Promise.resolve(data)
      })
  },
  deleteItem: <I>(item: I, options: DeleteItemOptions): ThunkResult => {
    if (!isValidItem(item)) {
      throw new Error('id property for item not defined')
    }
    const apiClient = getJsonApiClient()
    const apiUrl = apiClient.one(options.entityName, item.id)
    return (dispatch: Dispatch) =>
      apiRequest({
        apiAction: 'destroy',
        apiUrl,
        dispatch,
        entityName: options.entityName,
        flashMessages: options.flashMessages,
        id: item.id,
        requestPayload: item
      }).then((data: { payload: any }) => {
        if (data.payload.errors) {
          return Promise.reject(data)
        }
        const redirectLink = options.listPage()
        Router.push(redirectLink.href, redirectLink.as)

        return Promise.resolve(data)
      })
  }
}
export const actionCreators = { ...actionCreatorsSync, ...actionCreatorsAsync }

// Reducer
export function reducer (state: unknown, _action: DetailActions) {
  return state
}

// Side effects
export function isValidNonDraftItem<T> (entity: any): entity is T {
  return entity && entity.id && entity.id !== DRAFT_ID
}

export function isValidItem (item: any): item is WithUuid {
  return item && typeof item.id !== 'undefined'
}

export function getDefaultFlashMessages (listName: List) {
  return {
    success: (payload: any, options: any = {}) => {
      if (options.apiAction === 'destroy' && payload.meta.status) {
        return getSuccessFlashMessages(
          `${listName}.remove.${payload.meta.status}`,
          options
        )
      }
      const operationType = options.id === DRAFT_ID ? 'add' : 'edit'
      return getSuccessFlashMessages(
        `${listName}.${operationType}.success`,
        options
      )
    },
    failure: (payload: ErrorResponse, options: any = {}) => {
      if (
        options.apiAction === 'destroy' &&
        payload.meta &&
        payload.meta.status
      ) {
        return getFailureFlashMessages(
          `${listName}.remove.${payload.meta.status}`,
          payload
        )
      }
      return getFailureFlashMessages(`${listName}.edit.failure`, payload)
    }
  }
}
