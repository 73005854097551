// @flow
import { merge } from 'timm'
import Router from 'next/router'

import { actions as apiActions } from '../api'
import { getJsonApiClient, setEntityModel } from '../api/apiClient'
import apiRequest from '../api/request'
// $FlowIgnore TS import
import type { Action } from '../../types/Action'
import type { Entity } from '../../types/Entity'
// $FlowIgnore TS import
import type { GetState } from '../../types/ReduxType'
import { login } from '../routes'

// Constants
export const ENTITY: Entity = 'password_reset'
const flashMessages = {
  success: () => [
    {
      id: 'reset-password.success'
    }
  ],
  failure: () => [
    {
      id: 'reset-password.failure'
    }
  ]
}

// Actions
export const actions = {
  LOAD_RESET_PASSWORD_SUCCESS: apiActions.getItemSuccessActionName(ENTITY),
  LOAD_RESET_PASSWORD_FAILURE: apiActions.getFailureActionName(ENTITY),
  LOAD_RESET_PASSWORD_REQUEST: apiActions.getRequestActionName(ENTITY)
}

// Model and initial state
export const Model: {
  email: string
} = {
  email: ''
}

export const initialState: typeof Model & {
  error: boolean,
  isFetching: boolean
} = {
  email: '',
  error: false,
  isFetching: false
}

// Reducer
export default function reducer (
  state: typeof initialState = initialState,
  { payload, type }: Action
) {
  switch (type) {
    case actions.LOAD_RESET_PASSWORD_REQUEST:
      return merge(state, {
        error: false,
        isFetching: true
      })
    case actions.LOAD_RESET_PASSWORD_SUCCESS:
      if (payload instanceof Error) {
        return state
      }
      return merge(state, {
        error: false,
        isFetching: false
      })
    case actions.LOAD_RESET_PASSWORD_FAILURE:
      return merge(state, {
        error: payload,
        isFetching: false
      })
    default:
      return state
  }
}

// Action creators
export const actionCreators = {
  loadResetPassword () {
    const email = getEmailField()
    return async function (dispatch: *, getState: GetState) {
      return apiRequest({
        apiAction: 'post',
        apiModel: {
          email
        },
        apiUrl: getJsonApiClient().all(ENTITY),
        dispatch,
        entityName: ENTITY,
        flashMessages
      }).then(data => {
        const { href, as } = login({})
        Router.push(href, as)
        return Promise.resolve(data)
      })
    }
  }
}

// Side effects
setEntityModel(ENTITY, Model)

function getEmailField () {
  const emailElement = document.getElementById('email')
  if (!(emailElement instanceof HTMLInputElement)) {
    return
  }
  return emailElement.value
}
