// @flow
import { combineReducers } from 'redux'
import { default as cookies } from '../api/cookies'
import { default as api, initialState as apiInitialState } from '../api'
// $FlowIgnore ts import
import {
  default as drivers,
  initialState as driversInitialState
} from '../chauffeurs'
import {
  default as finished,
  initialState as finishedInitialState
} from '../rides/finished'
import {
  default as flashMessage,
  initialState as flashMessageInitialState
  // $FlowIgnore TS import
} from '../flashMessage'
// $FlowFixMe TypeScript import
import { default as kpi, initialState as kpiInitialState } from '../kpi'
// $FlowFixMe TypeScript import
import { default as login, initialState as loginInitialState } from '../login'
// $FlowFixMe TypeScript import
import { default as me, initialState as meInitialState } from '../me'
import {
  default as resetPassword,
  initialState as resetPasswordInitialState
} from '../resetPassword'
import {
  default as reviews,
  initialState as reviewsInitialState
} from '../reviews'
import {
  default as planned,
  initialState as plannedInitialState
  // $FlowIgnore TS import
} from '../rides/planned'
import {
  default as offers,
  initialState as offersInitialState
} from '../rides/offers'
import {
  default as pendingOffers,
  initialState as pendingOffersInitialState
} from '../rides/pendingOffers'
import {
  reducer as tracking,
  initialState as trackingInitialState
  // $FlowIgnore TS import
} from '../tracking'
import {
  default as vehicles,
  initialState as vehiclesInitialState
} from '../vehicles'

export type State = $ReadOnly<{|
  api: typeof apiInitialState,
  drivers: typeof driversInitialState,
  finished: typeof finishedInitialState,
  flashMessage: typeof flashMessageInitialState,
  kpi: typeof kpiInitialState,
  login: typeof loginInitialState,
  me: typeof meInitialState,
  offers: typeof offersInitialState,
  pendingOffers: typeof pendingOffersInitialState,
  planned: typeof plannedInitialState,
  resetPassword: typeof resetPasswordInitialState,
  reviews: typeof reviewsInitialState,
  tracking: typeof trackingInitialState,
  vehicles: typeof vehiclesInitialState
|}>

export const initialState: State = {
  api: apiInitialState,
  drivers: driversInitialState,
  finished: finishedInitialState,
  flashMessage: flashMessageInitialState,
  kpi: kpiInitialState,
  login: loginInitialState,
  me: meInitialState,
  offers: offersInitialState,
  pendingOffers: pendingOffersInitialState,
  planned: plannedInitialState,
  resetPassword: resetPasswordInitialState,
  reviews: reviewsInitialState,
  tracking: trackingInitialState,
  vehicles: vehiclesInitialState
}

export default combineReducers({
  api,
  cookies,
  drivers,
  finished,
  flashMessage,
  kpi,
  login,
  me,
  offers,
  pendingOffers,
  planned,
  resetPassword,
  reviews,
  tracking,
  vehicles
})
