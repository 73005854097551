import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

import reducers, { initialState as defaultState } from './reducer'

const middlewares = [thunkMiddleware]
if (process.env.NODE_ENV !== 'test') {
  middlewares.push(logger)
}

const enhancers = [applyMiddleware(...middlewares)]

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug User-defined debug mode param
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */
export const initStore = (initialState = defaultState) => {
  const store = createStore(reducers, initialState, compose(...enhancers))

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept(
      './reducer',
      () => store.replaceReducer(require('./reducer').default) // eslint-disable-line global-require
    )
  }

  return store
}
